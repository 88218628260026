import { gql } from "@apollo/client";
import { categoryFragment } from "../fragments/categoryFragment";

export const updateCategoryMutation = gql`
    mutation updateCategory($id: ID!, $input: UpdateCategoryInput!) {
        updateCategory(id: $id, input: $input) {
            ...Category
        }
    }
    ${categoryFragment}
`;
