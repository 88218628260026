import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { CategoryForm, CategoryFormValues } from "./CategoryForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class CategoryCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: CategoryFormValues, formikHelpers: FormikHelpers<CategoryFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createCategory({
                title: values.title,
                url: values.url,
                meta_description: values.meta_description || undefined,
                meta_keywords: values.meta_keywords || undefined,
                meta_title: values.meta_title || undefined,
                lead: values.lead || undefined,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.category.create.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.category.create.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.categoryList} />;
        }

        const initialValues: CategoryFormValues = {
            id: "",
            lead: "",
            title: "",
            meta_title: "",
            meta_description: "",
            meta_keywords: "",
            url: "",
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.categoryList} color="inherit">
                            {I18n.formatMessage({ id: "pages.categoryList.breadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.category.create.breadcrumb" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.category.create.title" })}
                    rightButtonsComponent={<FunctionalButton disabled type={FunctionalButtonIcon.delete} />}
                />
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={true}>
                        {props => (
                            <>
                                <CategoryForm formType={FormType.create} formProps={props} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(CategoryCreatePage));
