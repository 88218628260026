import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    TextField,
    Typography,
    Box,
    InputAdornment,
    Menu,
    MenuItem,
    IconButton,
    Table,
    TableRow,
    TableCell,
    Radio,
} from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import {
    BannerListItem,
    getPageById_page_blocks_PageBlockBanner_banners,
    getPageById_page_blocks_PageBlockProductList_product_list,
    Page,
    PageBlockBannerLayout,
    PageBlockType,
    PageInput,
    ProductListListItem,
    ProductListMinimal,
    Site,
} from "Api/graphql/admin/types";
import { Checkbox } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel, TitleBar } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { Constants } from "Utils/Constants";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { StatisticsPaper } from "Components/StatisticsPaper";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import { ArrowDropDown, ArrowDropUp, Close, EventBusy, Visibility } from "@bigfish/admin-ui/icons";
import { PreviewDialog } from "Components/PreviewDialog/PreviewDialog";
import SelectInput from "Components/SelectInput";
import "./styles.css";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { ProductListAddDialog } from "Components/ProductListAddDialog/ProductListAddDialog";
import { createModal } from "Components/createModal";
import ModalProductListEditPage from "Pages/ModalProductList/ModalProductListEditPage";
import { BannerAddDialog } from "Components/BannerAddDialog/BannerAddDialog";
import { DraggableBannerTable } from "Components/DraggableBannerTable";
import ModalBannerEditPage from "Pages/ModalBanner/ModalBannerEditPage";
import { ObjectUtils } from "Utils/ObjectUtils";
import { NumberUtils } from "Utils/NumberUtils";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";
import { BannerMinimal } from "Utils/ApiUtils";
import { Helpers } from "Utils/Helpers";

export enum ProductListSortFriendlyValues {
    ratingAsc = "ratingAsc",
    ratingDesc = "ratingDesc",
    orderedAsc = "orderedAsc",
    orderedDesc = "orderedDesc",
    nameAsc = "nameAsc",
    nameDesc = "nameDesc",
    priceAsc = "priceAsc",
    priceDesc = "priceDesc",
    priceUnitAsc = "priceUnitAsc",
    priceUnitDesc = "priceUnitDesc",
    none = "none",
}

export type BlocksFormValues = {
    id: number | null;
    delete: boolean;
    title: string | null;
    type: PageBlockType;
    banners?: getPageById_page_blocks_PageBlockBanner_banners[];
    layout?: ExtendedPageBlockBannerLayout | PageBlockBannerLayout;
    product_list?: getPageById_page_blocks_PageBlockProductList_product_list | null;
    content?: string;
    product_limit?: number;
    is_scrollable: boolean;
    product_sort_by: ProductListSortFriendlyValues | null;
    is_filterable: boolean;
};

export type PageFormValues = Omit<PageInput, "blocks" | "site_id"> & { blocks: BlocksFormValues[]; site_id: string | null };

type Props = {
    formType: FormType;
    formProps: FormikProps<PageFormValues>;
    page?: Page;
    selectSites: Site[];
    onDeleteClick?: () => void;
};

export enum ExtendedPageBlockBannerLayout {
    featured = "featured",
    single = "single",
}

const ModalProductListEditPageDialog = createModal(Constants.getModalPageStyles());
const ModalBannerEditPageDialog = createModal(Constants.getModalPageStyles());

export const pageValidator = (formType: FormType) => (values: PageFormValues): FormikErrors<PageFormValues> => {
    const errors: { [key in keyof PageFormValues]?: any } = {};

    if (!errors.active_from && formType === FormType.create) {
        errors.active_from = I18nHelpers.formatValidator(Validator.startDatePast)(values.active_from);
    }

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    const tooManyFilterables = values.blocks.reduce((sum: number, block: BlocksFormValues) => sum + (block.is_filterable ? 1 : 0), 0) > 1;

    errors.url = I18nHelpers.formatValidator(Validator.slug)(values.url);

    const blockErrors = values.blocks
        .map(b => {
            if (b.type === PageBlockType.product_list) {
                return {
                    product_list_id: I18nHelpers.formatValidator(Validator.required)(b.product_list),
                    is_filterable: tooManyFilterables ? I18n.formatMessage({ id: "pages.page.form.tooManyFilterables" }) : undefined,
                };
            } else if (b.type === PageBlockType.html) {
                return {
                    content: I18nHelpers.formatValidator(Validator.required)(b.content),
                };
            }
            return null;
        })
        .filter(Boolean);

    errors.blocks = blockErrors.length > 0 && blockErrors.find(e => Object.values(e as { [s: string]: string | undefined }).find(v => !!v)) ? blockErrors : undefined;

    return Form.cleanupFormikErrors(errors);
};

export const PageForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.pageList);
    }, [history]);

    const [isPreviewDialogVisible, setIsPreviewDialogVisible] = React.useState<boolean>(false);
    const [anchorElement, setAnchorElement] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
    const [contentedProductListBlockIndex, setContentedProductListBlockIndex] = React.useState<number | null>(null);
    const [editedProductListId, setEditedProductListId] = React.useState<number | null>(null);
    const [contentedBannerBlockIndex, setContentedBannerBlockIndex] = React.useState<number | null>(null);
    const [editedBannerId, setEditedBannerId] = React.useState<number | null>(null);

    const onAddClick = (type: PageBlockType): void => {
        onAddMenuClose();
        switch (type) {
            case PageBlockType.banner:
                props.formProps.setFieldValue("blocks", [
                    ...props.formProps.values.blocks,
                    { delete: false, title: "", type: PageBlockType.banner, banners: [], layout: PageBlockBannerLayout.full },
                ]);
                break;
            case PageBlockType.product_list:
                props.formProps.setFieldValue("blocks", [
                    ...props.formProps.values.blocks,
                    {
                        delete: false,
                        title: "",
                        type: PageBlockType.product_list,
                        product_list: undefined,
                        product_limit: undefined,
                        is_scrollable: false,
                        product_sort_by: null,
                        is_filterable: false,
                    },
                ]);
                break;
            case PageBlockType.html:
                props.formProps.setFieldValue("blocks", [...props.formProps.values.blocks, { delete: false, title: "", type: PageBlockType.html, content: "" }]);
                break;
            case PageBlockType.newsletter:
            case PageBlockType.brand_list:
            case PageBlockType.recommended_products:
            case PageBlockType.homebox:
                props.formProps.setFieldValue("blocks", [...props.formProps.values.blocks, { delete: false, title: "", type }]);
                break;

            default:
                break;
        }
    };

    const onAddMenuClose = () => {
        setAnchorElement(null);
    };

    const onModalProductListUpdate = (productList: ProductListMinimal | null) => {
        props.formProps.values.blocks.forEach((block: BlocksFormValues, index: number) => {
            if (block.product_list && productList && block.product_list?.id === productList?.id) {
                props.formProps.setFieldValue(`blocks[${index}].product_list`, productList);
            }
        });
    };

    const onModalBannerUpdate = (banner: BannerMinimal | null) => {
        props.formProps.values.blocks.forEach((block: BlocksFormValues, index: number) => {
            if (block.banners && banner && block.banners.find(b => b.id === banner.id)) {
                const newBanners = [...block.banners];
                const indexOfChanged = block.banners.findIndex(b => b.id === banner.id);
                newBanners[indexOfChanged] = { ...banner };

                props.formProps.setFieldValue(`blocks[${index}].banners`, newBanners);
            }
        });
    };

    const renderRightButtonsComponent = (previewEnabled: boolean) => {
        return (
            <div className="detailed-display-right-button-component">
                <Button variant="outlined" color="primary" startIcon={<Visibility />} disabled={!previewEnabled} onClick={() => setIsPreviewDialogVisible(true)}>
                    {I18n.formatMessage({ id: "common.form.preview" })}
                </Button>
                <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="contained" size="medium" color="secondary" onClick={e => setAnchorElement(e.currentTarget)}>
                    {I18n.formatMessage({ id: "pages.page.form.addBlockButton" })}
                </Button>
                <Menu id="simple-menu" anchorEl={anchorElement} keepMounted open={!!anchorElement} onClose={onAddMenuClose}>
                    {ObjectUtils.enumAsArray<PageBlockType>(PageBlockType).map((pageBlockType: PageBlockType) => (
                        <MenuItem key={`menu-item-${pageBlockType}`} onClick={() => onAddClick(pageBlockType)}>
                            {Constants.getPageBlockTypeTitle(pageBlockType)}
                        </MenuItem>
                    ))}
                </Menu>
                <FunctionalButton onClick={props.onDeleteClick} type={FunctionalButtonIcon.delete} />
            </div>
        );
    };

    const removeBlockByIndex = (index: number) => {
        const newBlocks = [...props.formProps.values.blocks];
        newBlocks[index] = { ...newBlocks[index], delete: true };
        props.formProps.setFieldValue("blocks", newBlocks);
    };

    const renderPositionArrows = (index: number) => {
        const blocks = props.formProps.values.blocks;
        return (
            <div className="position-arrows">
                {index > 0 && (
                    <ArrowDropUp
                        onClick={() => {
                            const newBlocks = [...blocks];
                            newBlocks[index - 1] = { ...blocks[index] };
                            newBlocks[index] = { ...blocks[index - 1] };
                            props.formProps.setFieldValue("blocks", newBlocks, false);
                        }}
                    />
                )}
                {index + 1 < blocks.length && (
                    <ArrowDropDown
                        onClick={() => {
                            const newBlocks = [...blocks];
                            newBlocks[index + 1] = { ...blocks[index] };
                            newBlocks[index] = { ...blocks[index + 1] };
                            props.formProps.setFieldValue("blocks", newBlocks, false);
                        }}
                    />
                )}
            </div>
        );
    };

    const renderBannerBlock = (index: number) => {
        const isAddDisabled = () => {
            const block = props.formProps.values.blocks[index];
            switch (block.layout) {
                case ExtendedPageBlockBannerLayout.featured:
                case ExtendedPageBlockBannerLayout.single:
                    return block.banners && block.banners.length > 0;
                default:
                    return false;
            }
        };

        return (
            <div key={`block-${index}`} className={props.formProps.values.blocks[index].delete ? "deleted-block" : ""}>
                <PageCard.Container>
                    <PageCard.Heading
                        title={I18n.formatMessage({ id: "pages.page.form.bannerBlock.title" })}
                        rightContent={
                            <div className="detailed-display-right-button-component">
                                <Button
                                    startIcon={<Icon className="fa fa-plus-circle" />}
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={() => setContentedBannerBlockIndex(index)}
                                    disabled={isAddDisabled()}
                                >
                                    {I18n.formatMessage({ id: "pages.page.form.addBannerButton" })}
                                </Button>
                                <IconButton className="block-delete-button" onClick={() => removeBlockByIndex(index)}>
                                    <Close />
                                </IconButton>
                            </div>
                        }
                    />
                    <Box mt="15px" />
                    <Field name={`blocks[${index}].layout`} validate={I18nHelpers.formatValidator(Validator.required)}>
                        {({ field, meta }: FieldProps) => (
                            <SelectInput
                                label={I18n.formatMessage({ id: "pages.page.form.bannerBlock.layoutLabel" })}
                                fullWidth
                                variant="outlined"
                                options={[
                                    {
                                        id: index === 0 ? ExtendedPageBlockBannerLayout.featured : ExtendedPageBlockBannerLayout.single,
                                        title: I18n.formatMessage({
                                            id: `enums.extendedPageBlockBannerLayout.${
                                                index === 0 ? ExtendedPageBlockBannerLayout.featured : ExtendedPageBlockBannerLayout.single
                                            }`,
                                        }),
                                    },
                                    ...ObjectUtils.enumAsArray<PageBlockBannerLayout>(PageBlockBannerLayout).map((layout: PageBlockBannerLayout) => {
                                        return { id: layout, title: I18n.formatMessage({ id: `enums.pageBlockBannerLayout.${layout}` }) };
                                    }),
                                ]}
                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                {...field}
                                error={meta.touched && !!meta.error}
                                onChange={(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                                    field.onChange(event);
                                    props.formProps.setFieldValue(`blocks[${index}].banners`, []);
                                }}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name={`blocks[${index}].title`}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.page.form.bannerBlock.titleLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, "")}
                                error={meta.touched && !!meta.error}
                                {...field}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <DraggableBannerTable
                        banners={props.formProps.values.blocks[index].banners ?? []}
                        onChange={newBanners => {
                            props.formProps.setFieldValue(`blocks[${index}].banners`, newBanners);
                        }}
                        onBannerEditClick={(id: number) => setEditedBannerId(id)}
                    />

                    {renderPositionArrows(index)}
                </PageCard.Container>
            </div>
        );
    };

    const renderProductListBlock = (index: number) => {
        const productList = props.formProps.values.blocks[index].product_list;

        return (
            <div key={`block-${index}`} className={props.formProps.values.blocks[index].delete ? "deleted-block" : ""}>
                <PageCard.Container>
                    <PageCard.Heading
                        title={I18n.formatMessage({ id: "pages.page.form.productListBlock.title" })}
                        rightContent={
                            <div className="detailed-display-right-button-component">
                                <Button
                                    startIcon={<Icon className="fa fa-plus-circle" />}
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={() => setContentedProductListBlockIndex(index)}
                                >
                                    {I18n.formatMessage({ id: "pages.page.form.addProductListButton" })}
                                </Button>
                                <IconButton className="block-delete-button" onClick={() => removeBlockByIndex(index)}>
                                    <Close />
                                </IconButton>
                            </div>
                        }
                    />
                    <Box mt="15px" />

                    <Field name={`blocks[${index}].title`}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.page.form.productListBlock.titleLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, "")}
                                error={meta.touched && !!meta.error}
                                {...field}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name={`blocks[${index}].product_limit`}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="number"
                                label={I18n.formatMessage({ id: "pages.page.form.productListBlock.productLimitLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, "")}
                                error={meta.touched && !!meta.error}
                                {...field}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                        field.onChange(e);
                                    }
                                }}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name={`blocks[${index}].is_filterable`}>
                        {({ field, meta }: FieldProps) =>
                            field.value !== undefined && (
                                <Box mt="15px" ml="10px">
                                    <FormControl error={meta.touched && !!meta.error}>
                                        <FormControlLabel
                                            checked={!!props.formProps.values.blocks[index].is_filterable && !props.formProps.values.blocks[index].is_scrollable}
                                            control={
                                                <Radio
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={() => {
                                                        props.formProps.setFieldValue(`blocks[${index}].is_filterable`, true);
                                                        props.formProps.setFieldValue(`blocks[${index}].is_scrollable`, false);
                                                    }}
                                                />
                                            }
                                            label={I18n.formatMessage({ id: "pages.page.form.productListBlock.isFilterableLabel" })}
                                        />
                                    </FormControl>
                                    <Box mt="15px" />
                                    <CustomFormHelperText meta={meta} alwaysShowError />
                                </Box>
                            )
                        }
                    </Field>

                    <Box mt="30px" />

                    <Field name={`blocks[${index}].is_scrollable`}>
                        {({ field, meta }: FieldProps) =>
                            field.value !== undefined && (
                                <Box mt="15px" ml="10px">
                                    <FormControl error={meta.touched && !!meta.error}>
                                        <FormControlLabel
                                            checked={!!props.formProps.values.blocks[index].is_scrollable && !props.formProps.values.blocks[index].is_filterable}
                                            control={
                                                <Radio
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={() => {
                                                        props.formProps.setFieldValue(`blocks[${index}].is_filterable`, false);
                                                        props.formProps.setFieldValue(`blocks[${index}].is_scrollable`, true);
                                                    }}
                                                />
                                            }
                                            label={I18n.formatMessage({ id: "pages.page.form.productListBlock.isScrollableLabel" })}
                                        />
                                    </FormControl>
                                    <Box mt="15px" />
                                    <CustomFormHelperText
                                        meta={meta}
                                        defaultHelperText={
                                            props.formProps.values.blocks[index].product_list
                                                ? undefined
                                                : I18n.formatMessage({ id: "pages.page.form.productListBlock.noSegmentAdded" })
                                        }
                                    />
                                </Box>
                            )
                        }
                    </Field>

                    <Box mt="30px" />

                    <Field name={`blocks[${index}].product_sort_by`}>
                        {({ field, meta }: FieldProps) => (
                            <SelectInput
                                label={I18n.formatMessage({ id: "pages.page.form.productListBlock.productSortByLabel" })}
                                fullWidth
                                variant="outlined"
                                options={ObjectUtils.enumAsArray<ProductListSortFriendlyValues>(ProductListSortFriendlyValues).map(value => ({
                                    id: value,
                                    title: I18n.formatMessage({ id: `enums.productListSortFriendlyValues.${value}` }),
                                }))}
                                helperText={Form.getHelperText(meta, "")}
                                {...field}
                                error={meta.touched && !!meta.error}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    {productList && (
                        <Table>
                            <TableRow>
                                <TableCell className="draggable-table-icon-cell" align="left">
                                    <span className="draggable-icon-wrapper" onClick={() => setEditedProductListId(productList.id)}>
                                        <Icon className="fa fa-pencil-alt draggable-icon" title={I18n.formatMessage({ id: "common.edit" })} />
                                    </span>
                                </TableCell>
                                <TableCell align="left">{`ID:${productList.id} - ${productList.name}`}</TableCell>
                                <TableCell
                                    className="draggable-table-icon-cell"
                                    align="right"
                                    onClick={() => {
                                        props.formProps.setFieldValue(`blocks[${index}].product_list`, null);
                                    }}
                                >
                                    <span className="draggable-icon-wrapper">
                                        <IconButton className="table-delete-icon">
                                            <Close />
                                        </IconButton>
                                    </span>
                                </TableCell>
                            </TableRow>
                        </Table>
                    )}

                    {renderPositionArrows(index)}
                </PageCard.Container>
            </div>
        );
    };

    const renderHtmlBlock = (index: number) => {
        return (
            <div key={`block-${index}`} className={props.formProps.values.blocks[index].delete ? "deleted-block" : ""}>
                <PageCard.Container>
                    <PageCard.Heading
                        title={I18n.formatMessage({ id: "pages.page.form.htmlBlock.title" })}
                        rightContent={
                            <IconButton className="block-delete-button" onClick={() => removeBlockByIndex(index)}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <Box mt="15px" />

                    <Field name={`blocks[${index}].content`}>
                        {({ field, meta }: FieldProps) => (
                            <div>
                                <RichTextEditor
                                    config="pageHtmlBlock"
                                    {...field}
                                    value={props.formProps.values.blocks[index].content ?? ""}
                                    onChange={(content: string) => {
                                        props.formProps.setFieldValue(`blocks[${index}].content`, content);
                                    }}
                                />
                                <CustomFormHelperText meta={meta} defaultHelperText={I18n.formatMessage({ id: "common.required" })} />
                            </div>
                        )}
                    </Field>
                    {renderPositionArrows(index)}
                </PageCard.Container>
            </div>
        );
    };

    const renderNewsletterBlock = (index: number) => {
        return (
            <div key={`block-${index}`} className={props.formProps.values.blocks[index].delete ? "deleted-block" : ""}>
                <PageCard.Container>
                    <PageCard.Heading
                        title={I18n.formatMessage({ id: "pages.page.form.newsletterBlock.title" })}
                        rightContent={
                            <IconButton className="block-delete-button" onClick={() => removeBlockByIndex(index)}>
                                <Close />
                            </IconButton>
                        }
                    />
                    {renderPositionArrows(index)}
                </PageCard.Container>
            </div>
        );
    };

    const renderBrandListBlock = (index: number) => {
        return (
            <div key={`block-${index}`} className={props.formProps.values.blocks[index].delete ? "deleted-block" : ""}>
                <PageCard.Container>
                    <PageCard.Heading
                        title={I18n.formatMessage({ id: "pages.page.form.brandListBlock.title" })}
                        rightContent={
                            <IconButton className="block-delete-button" onClick={() => removeBlockByIndex(index)}>
                                <Close />
                            </IconButton>
                        }
                    />
                    {renderPositionArrows(index)}
                </PageCard.Container>
            </div>
        );
    };

    const renderRecommendedProductsBlock = (index: number) => {
        return (
            <div key={`block-${index}`} className={props.formProps.values.blocks[index].delete ? "deleted-block" : ""}>
                <PageCard.Container>
                    <PageCard.Heading
                        title={I18n.formatMessage({ id: "pages.page.form.recommendedProductsBlock.title" })}
                        rightContent={
                            <IconButton className="block-delete-button" onClick={() => removeBlockByIndex(index)}>
                                <Close />
                            </IconButton>
                        }
                    />
                    {renderPositionArrows(index)}
                </PageCard.Container>
            </div>
        );
    };

    const renderHomeboxBlock = (index: number) => {
        return (
            <div key={`block-${index}`} className={props.formProps.values.blocks[index].delete ? "deleted-block" : ""}>
                <PageCard.Container>
                    <PageCard.Heading
                        title={I18n.formatMessage({ id: "pages.page.form.homeboxBlock.title" })}
                        rightContent={
                            <IconButton className="block-delete-button" onClick={() => removeBlockByIndex(index)}>
                                <Close />
                            </IconButton>
                        }
                    />
                    {renderPositionArrows(index)}
                </PageCard.Container>
            </div>
        );
    };

    return (
        <Form formProps={props.formProps}>
            <TitleBar
                title={props.formType === FormType.create ? I18n.formatMessage({ id: "pages.page.new" }) : props.page?.name ?? ""}
                rightButtonsComponent={renderRightButtonsComponent(props.formProps.dirty && props.formProps.isValid)}
            />
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

                {props.formType === FormType.edit && (
                    <div>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.page.form.idLabel" })}
                        </Typography>
                        <Typography variant="subtitle1">{props.page?.id}</Typography>
                    </div>
                )}

                <Box mt="30px" />

                <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.page.form.nameLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const url = Helpers.updateSlug(field.value, e.currentTarget.value, "");
                                props.formProps.setFieldValue("url", url);
                                props.formProps.setFieldTouched("url", true);
                                field.onChange(e);
                            }}
                        />
                    )}
                </Field>
                <Box mt="30px" />

                <Field name="title">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.page.form.titleLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, "")}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="url" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.page.form.urlLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

                <Field name="site_id" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <SelectInput
                            key={props.formProps.values.site_id}
                            label={I18n.formatMessage({ id: "pages.page.form.siteIdLabel" })}
                            fullWidth
                            variant="outlined"
                            options={props.selectSites.map((s: Site) => {
                                return { id: s.id, title: s.name };
                            })}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            {...field}
                            error={meta.touched && !!meta.error}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.page.form.statusLabel" })}
                </Typography>

                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "pages.page.form.isActiveLabel" })} />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Field name="is_header">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "pages.page.form.isHeaderLabel" })} />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Field name="is_footer">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "pages.page.form.isFooterLabel" })} />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Grid container>
                    <Grid item>
                        <Box mr="30px">
                            <Field name="active_from">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        id="active_from"
                                        label={I18n.formatMessage({ id: "pages.page.form.activeFromLabel" })}
                                        type="datetime-local"
                                        className={`datetime-local ${props.formProps.values.active_from ? "datetime-local-filled" : ""}`}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: props.formProps.values.active_from ? (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => {
                                                        props.formProps.setFieldValue("active_from", "");
                                                        props.formProps.setFieldTouched("active_from", true);
                                                    }}
                                                >
                                                    <EventBusy />
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                        {...field}
                                        value={field.value}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                            field.onChange(e);
                                        }}
                                        error={meta.touched && !!meta.error}
                                        helperText={Form.getHelperText(meta, "")}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Field name="active_to">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    id="active_to"
                                    label={I18n.formatMessage({ id: "pages.page.form.activeToLabel" })}
                                    className={`datetime-local ${props.formProps.values.active_to ? "datetime-local-filled" : ""}`}
                                    type="datetime-local"
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: props.formProps.values.active_to ? (
                                            <InputAdornment
                                                position="end"
                                                onClick={() => {
                                                    props.formProps.setFieldValue("active_to", "");
                                                    props.formProps.setFieldTouched("active_to", true);
                                                }}
                                            >
                                                <EventBusy />
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    {...field}
                                    value={field.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                        field.onChange(e);
                                    }}
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, "")}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>
            </PageCard.Container>

            {props.formProps.values.blocks.map((block: BlocksFormValues, index: number) => {
                switch (block.type) {
                    case PageBlockType.banner:
                        return renderBannerBlock(index);
                    case PageBlockType.product_list:
                        return renderProductListBlock(index);
                    case PageBlockType.html:
                        return renderHtmlBlock(index);
                    case PageBlockType.newsletter:
                        return renderNewsletterBlock(index);
                    case PageBlockType.brand_list:
                        return renderBrandListBlock(index);
                    case PageBlockType.recommended_products:
                        return renderRecommendedProductsBlock(index);
                    case PageBlockType.homebox:
                        return renderHomeboxBlock(index);
                    default:
                        return null;
                }
            })}

            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.metaData" })} />
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "common.form.ogImage" })}
                </Typography>
                <Box className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field key={"og_image"} name={"og_image"}>
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="product-category-og_image_url"
                                            name="og_image"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("og_image", value);
                                                props.formProps.setFieldTouched("og_image", true);
                                            }}
                                        />
                                        <CustomFormHelperText meta={meta} />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-title-tag-field">
                        <Field key={I18n.formatMessage({ id: "common.form.ogTitle" })} name="og_title" validate={I18nHelpers.formatValidator(Validator.ogTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.ogTitle" })}
                                    fullWidth
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogTitleMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.ogTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-og-description-field">
                        <Field
                            key={I18n.formatMessage({ id: "common.form.ogDescription" })}
                            name="og_description"
                            validate={I18nHelpers.formatValidator(Validator.ogDescriptionMaxLength)}
                        >
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.ogDescription" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogDescriptionMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.ogDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-meta-title-field">
                        <Field key={I18n.formatMessage({ id: "common.form.title" })} name="meta_title" validate={I18nHelpers.formatValidator(Validator.metaTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.title" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.metaTitleMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.metaTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-meta-description-field">
                        <Field
                            key={I18n.formatMessage({ id: "common.form.metaDescription" })}
                            name="meta_description"
                            validate={I18nHelpers.formatValidator(Validator.metaDescriptionMaxLength)}
                        >
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.metaDescription" })}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.metaDescriptionMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.metaDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>
            </PageCard.Container>

            {props.formType === FormType.edit && props.page && (
                <StatisticsPaper
                    createdAt={props.page.created_at}
                    createdBy={props.page.created_by?.name}
                    updatedAt={props.page.updated_at}
                    updatedBy={props.page.updated_by?.name}
                />
            )}

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>

            <FullscreenLoader visible={props.formProps.isSubmitting} />

            {contentedBannerBlockIndex !== null && (
                <BannerAddDialog
                    key={`banner-add-dialog-${contentedBannerBlockIndex}`}
                    onClose={() => setContentedBannerBlockIndex(null)}
                    isSingleSelect={
                        (props.formProps.values.blocks[contentedBannerBlockIndex].layout &&
                            ExtendedPageBlockBannerLayout.featured === props.formProps.values.blocks[contentedBannerBlockIndex].layout) ||
                        ExtendedPageBlockBannerLayout.single === props.formProps.values.blocks[contentedBannerBlockIndex].layout
                    }
                    onAddClick={(newBanners: Array<BannerMinimal | BannerListItem>) => {
                        props.formProps.setFieldValue(
                            `blocks[${contentedBannerBlockIndex}].banners`,
                            newBanners.map(b => {
                                return { id: b.id, name: b.name };
                            })
                        );
                        setContentedBannerBlockIndex(null);
                    }}
                    initialSelectedBanners={props.formProps.values.blocks[contentedBannerBlockIndex].banners ?? []}
                    onlyActive
                />
            )}

            {editedBannerId && (
                <ModalBannerEditPageDialog title={null} open onClose={() => setEditedBannerId(null)}>
                    <ModalBannerEditPage id={editedBannerId} onModalClose={() => setEditedBannerId(null)} onUpdate={onModalBannerUpdate} />
                </ModalBannerEditPageDialog>
            )}

            {contentedProductListBlockIndex !== null && (
                <ProductListAddDialog
                    key={`product-list-add-dialog-${contentedProductListBlockIndex}`}
                    isSingleSelect
                    onClose={() => setContentedProductListBlockIndex(null)}
                    onAddClick={(newProductLists: Array<ProductListMinimal | ProductListListItem>) => {
                        props.formProps.setFieldValue(`blocks[${contentedProductListBlockIndex}].product_list`, { id: newProductLists[0].id, name: newProductLists[0].name });
                        setContentedProductListBlockIndex(null);
                    }}
                    initialSelectedProductLists={[props.formProps.values.blocks[contentedProductListBlockIndex].product_list].filter(Boolean) as ProductListListItem[]}
                    onlyActive
                />
            )}

            {editedProductListId && (
                <ModalProductListEditPageDialog title={null} open onClose={() => setEditedProductListId(null)}>
                    <ModalProductListEditPage id={editedProductListId} onModalClose={() => setEditedProductListId(null)} onUpdate={onModalProductListUpdate} />
                </ModalProductListEditPageDialog>
            )}

            <PreviewDialog
                isVisible={isPreviewDialogVisible}
                onClose={() => setIsPreviewDialogVisible(false)}
                previewUrl={`${process.env.REACT_APP_WEBSITE_URL}${Constants.webshopPreviewUrls.page}`}
                getPreviewData={() => {
                    return {
                        ...props.formProps.values,
                    };
                }}
            />
        </Form>
    );
};
