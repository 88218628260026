import React from "react";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { GiftCardOrderItem } from "Api/graphql/admin/types";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { Constants } from "Utils/Constants";

type Props = WithStyles<typeof dataGridStyles> & {
    items: GiftCardOrderItem[];
};

class GiftCardOrderItemListDataGrid extends React.Component<Props> {
    private getDataSource = () => async (): Promise<DataSource<GiftCardOrderItem>> => {
        return { data: this.props.items, count: this.props.items.length };
    };

    private getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "id",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.id" }),
                defaultWidth: 130,
                filterDelay: Constants.filterDelayMS,
                sortable: false,
                filterable: false,
            },
            {
                name: "quantity",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.quantity" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                filterable: false,
            },
            {
                name: "value",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.value" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                filterable: false,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },
            {
                name: "design",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.design" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                filterable: false,
            },
            {
                name: "sub_total",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.sub_total" }),
                defaultWidth: 220,
                sortable: false,
                filterable: false,
                filterEditor: NumberFilter,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },
            {
                name: "name_from",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.name_from" }),
                defaultFlex: 1,
                minWidth: 250,
                sortable: false,
                filterable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "name_to",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.name_to" }),
                defaultFlex: 1,
                minWidth: 250,
                sortable: false,
                filterable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "mail_to",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.mail_to" }),
                defaultFlex: 1,
                minWidth: 250,
                sortable: false,
                filterable: false,
                render: ({ data }: { data: GiftCardOrderItem }) => {
                    return <div>{data.mail_to && data.mail_to.length > 0 ? data.mail_to.map(mt => mt).join(", ") : ""}</div>;
                },
            },
        ];
    };

    render() {
        return (
            <UnControlledDataGrid<GiftCardOrderItem>
                className={this.props.classes.dataGrid}
                rowHeight={50}
                style={{ minHeight: 103 + this.props.items.length * 50 }}
                dataSource={this.getDataSource()}
                columns={this.getColumns()}
                activeCell={null}
                onRowClick={() => {}}
                pagination={false}
            />
        );
    }
}

export default withStyles(dataGridStyles, { withTheme: true })(GiftCardOrderItemListDataGrid);
