import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { BadgeForm, BadgeFormValues, badgeValidator } from "./BadgeForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Badge, listProductParamSelectItems_productParamList_data, OrderDirection, ProductParamSortField } from "Api/graphql/admin/types";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    badge: Badge | null;
    productParams: listProductParamSelectItems_productParamList_data[];
    isDeleteDialogVisible: boolean;
};

class BadgeEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        badge: null,
        productParams: [],
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const badge = await Api.getBadgeById(Number.parseInt(this.props.match.params.id, 10));
            const { data: productParams } = await Api.listProductParamSelectItems({
                first: 9999,
                sortBy: { field: ProductParamSortField.name, direction: OrderDirection.ASC },
            });
            this.setState({ badge, productParams: [...productParams].sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "")), isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.badgeList);
        }
    }

    private onSubmit = async (values: BadgeFormValues, formikHelpers: FormikHelpers<BadgeFormValues>): Promise<boolean> => {
        try {
            if (!values.type) return false;
            const badge = await Api.updateBadge(this.state.badge!.id, { ...values, type: values.type });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ badge });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.badgeList} color="inherit">
                    {I18n.formatMessage({ id: "pages.badge.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.badge?.name || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteBadge(this.state.badge!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ badge: null });
        } catch (error) {
            const errorMessage = error instanceof ApiError ? ` ${error.message}` ?? "" : "";
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.edit.deleteError" }) + errorMessage, { variant: "error", transitionDuration: { appear: 7000 } });
        }
    };

    public render() {
        const { isLoading, badge, productParams } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!badge) {
            return <Redirect to={Path.badgeList} />;
        }

        const initialValues: BadgeFormValues = {
            name: badge.name,
            type: badge.type,
            image: badge.image ?? "",
            image_title: badge.image_title,
            info: badge.info,
            product_param_id: badge.product_param?.id ?? null,
            is_active: badge.is_active,
            active_from: badge.active_from ?? null,
            active_to: badge.active_to,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={badge.name} rightButtonsComponent={<FunctionalButton onClick={this.onDeleteButtonClick} type={FunctionalButtonIcon.delete} />} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={badgeValidator(FormType.edit)} enableReinitialize={true}>
                    {props => (
                        <>
                            <BadgeForm formType={FormType.edit} formProps={props} badge={badge} productParams={productParams} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.badge.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.badge.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.onDeleteDialogClose}
                    onFunctionClick={this.onDeleteClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(BadgeEditPage));
