import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ProductVariantForm, ProductVariantFormValues, productVariantValidator } from "./ProductVariantForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { ProductVariant, PermissionType } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Box } from "@bigfish/admin-ui/core";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { connect } from "react-redux";

type RouteParams = {
    id?: string;
};

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams> & ReduxProps;

type State = {
    isLoading: boolean;
    productVariant: ProductVariant | null;
    isDeleteDialogVisible: boolean;
};

class ProductVariantEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        productVariant: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const productVariant = await Api.getProductVariantById(Number.parseInt(this.props.match.params.id));
            this.setState({ productVariant, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.productVariantList);
        }
    }

    private onSubmit = async (values: ProductVariantFormValues, formikHelpers: FormikHelpers<ProductVariantFormValues>): Promise<boolean> => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const productVariant = await Api.updateProductVariant(this.state.productVariant!.id, {
                ...values,
                children: values.children.map(c => c.id),
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productVariant.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ productVariant });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productVariant.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.productVariantList} color="inherit">
                    {I18n.formatMessage({ id: "pages.productVariant.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.productVariant?.id || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    private toggleDeleteDialogVisibility = () => {
        this.setState({ isDeleteDialogVisible: !this.state.isDeleteDialogVisible });
    };

    private deleteProductVariant = async (): Promise<void> => {
        if (!(this.props.isSuperadmin || this.props.permissions.includes(PermissionType.product_variant_delete))) return;

        try {
            await Api.deleteProductVariant(this.state.productVariant!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productVariant.form.deleteSuccess" }), { variant: "success" });
            this.props.history.push(Path.productVariantList);
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            } else {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productVariant.form.deleteError" }), { variant: "error" });
            }
        }
    };

    public render() {
        const { isLoading, productVariant } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!productVariant) {
            return <Redirect to={Path.productVariantList} />;
        }

        const initialValues: ProductVariantFormValues = {
            product_param_id: productVariant.variant_product_param_id ?? "",
            children: productVariant.children ?? [],
            name: productVariant.name ?? "",
            is_active: productVariant.is_active,
            active_from: productVariant.active_from ? DateUtils.format(DateUtils.parseISO(productVariant.active_from), DateFormat.input) : "",
            active_to: productVariant.active_to ? DateUtils.format(DateUtils.parseISO(productVariant.active_to), DateFormat.input) : "",
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar
                    title={productVariant.name ?? ""}
                    rightButtonsComponent={<FunctionalButton onClick={this.toggleDeleteDialogVisibility} type={FunctionalButtonIcon.delete} />}
                />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={productVariantValidator()} enableReinitialize={true}>
                    {props => (
                        <>
                            <ProductVariantForm formType={FormType.edit} formProps={props} productVariant={productVariant} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>

                <Box mt="40px" />

                {this.props.isSuperadmin || this.props.permissions.includes(PermissionType.product_variant_delete) ? (
                    <FunctionConfirmModal
                        title={I18n.formatMessage({ id: "pages.productVariant.form.deleteModal.title" })}
                        description={I18n.formatMessage({ id: "pages.productVariant.form.deleteModal.description" })}
                        isVisible={this.state.isDeleteDialogVisible}
                        onClose={this.toggleDeleteDialogVisibility}
                        onFunctionClick={this.deleteProductVariant}
                        rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                        leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    />
                ) : (
                    <></>
                )}
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withRouter(ProductVariantEditPage)));
