import { gql } from "@apollo/client";

export const detachDocumentMutation = gql`
    mutation detachDocument($contentId: ID!, $documentId: ID!) {
        detachDocument(contentId: $contentId, documentId: $documentId) {
            ... on TextContent {
                id
            }
        }
    }
`;
