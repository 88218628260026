import { gql } from "@apollo/client";
import { productParamFragment } from "./../fragments/productParamFragment";

export const updateProductParamMutation = gql`
    mutation updateProductParam($id: String!, $input: ProductParamUpdateInput!) {
        updateProductParam(id: $id, input: $input) {
            ...ProductParam
        }
    }
    ${productParamFragment}
`;
