import { gql } from "@apollo/client";

export const updateContentMutation = gql`
    mutation updateContent($id: ID!, $input: UpdateContentInput!) {
        updateContent(id: $id, input: $input) {
            ... on TextContent {
                id
            }
        }
    }
`;
