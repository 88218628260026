import React from "react";
import { Box, Tab, Tabs } from "@bigfish/admin-ui/core";
import { TabPanel } from "@bigfish/admin-ui/components";
import { TabsComponentProps } from "@bigfish/admin-ui/components/TabsComponent";

export const ScrollableTabsComponent = (props: TabsComponentProps) => {
    const { ariaLabel, tabs, currentTab, changeTab } = props;

    return (
        <Box>
            <Tabs value={currentTab} onChange={changeTab} aria-label={ariaLabel} variant="scrollable" scrollButtons="auto">
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} id={tab.id} aria-controls={`tabpanel-${tab.ariaLabel}-${tab.label}`} />
                ))}
            </Tabs>
            {tabs.map((tab, index) => (
                <TabPanel key={index} value={currentTab} index={index} id={`tabpanel-${tab.label}`} aria-labelledby={tab.ariaLabel}>
                    {tab.content}
                </TabPanel>
            ))}
        </Box>
    );
};
