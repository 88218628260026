import { gql } from "@apollo/client";
import { documentModalListItemFragment } from "../fragments/documentModalListItemFragment";

export const uploadDocumentMutation = gql`
    mutation uploadDocument($file: Upload!) {
        uploadDocument(file: $file) {
            ...DocumentModalListItem
        }
    }
    ${documentModalListItemFragment}
`;
