import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { GiftCardOrder, PermissionType } from "Api/graphql/admin/types";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { GiftCardOrderInfo } from "./GiftCardOrderInfo";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { I18n } from "I18n/I18n";

type RouteParams = {
    id?: string;
};

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams> & ReduxProps;

type State = {
    isLoading: boolean;
    giftCardOrder: GiftCardOrder | null;
    isCloseOrderDialogVisible: boolean;
    isDeleteDialogVisible: boolean;
    refreshIndex: number;
};

class GiftCardOrderPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        giftCardOrder: null,
        isCloseOrderDialogVisible: false,
        isDeleteDialogVisible: false,
        refreshIndex: 0,
    };

    public async componentDidMount(): Promise<void> {
        this.fetchGiftCardOrder(Number.parseInt(this.props.match.params.id!, 10))();
    }

    private fetchGiftCardOrder = (id: number) => async (): Promise<void> => {
        try {
            const giftCardOrder = await Api.giftCardOrder(id);
            this.setState({ giftCardOrder, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.giftCardOrderList);
        }
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.giftCardOrderList} color="inherit">
                    {I18n.formatMessage({ id: "pages.giftCardOrder.info.breadcrumb" })}
                </Link>
                {this.state.giftCardOrder?.id && <ActiveBreadcrumbItem aria-current="page">{this.state.giftCardOrder.id}</ActiveBreadcrumbItem>}
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, giftCardOrder } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!giftCardOrder) {
            return <Redirect to={Path.giftCardOrderList} />;
        }

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <GiftCardOrderInfo giftCardOrder={giftCardOrder!} />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(GiftCardOrderPage));
