import React from "react";
import { Button, IconButton } from "@bigfish/admin-ui/core";
import { AttachMoney, DeleteForever, Undo } from "@bigfish/admin-ui/icons";
import { Block } from "@bigfish/admin-ui/icons";
import { Healing } from "@bigfish/admin-ui/icons";

export enum FunctionalButtonIcon {
    delete = "delete",
    closeOrder = "closeOrder",
    correctInvoice = "correctInvoice",
    storno = "storno",
    refund = "refund",
}

type Props = {
    onClick?: () => void;
    disabled?: boolean;
    type: FunctionalButtonIcon;
    label?: string;
};

export const FunctionalButton = (props: Props) => {
    const renderIcon = () => {
        switch (props.type) {
            case FunctionalButtonIcon.delete:
                return <DeleteForever />;
            case FunctionalButtonIcon.closeOrder:
                return <Block />;
            case FunctionalButtonIcon.correctInvoice:
                return <Healing />;
            case FunctionalButtonIcon.storno:
                return <Undo />;
            case FunctionalButtonIcon.refund:
                return <AttachMoney />;
            default:
                return null;
        }
    };

    return props.label ? (
        <Button
            style={props.disabled ? undefined : { color: "#294475", border: "1px solid #7895C1" }}
            aria-label="Primary"
            startIcon={renderIcon()}
            variant="outlined"
            color="secondary"
            disabled={!!props.disabled}
            onClick={props.onClick ? props.onClick : () => {}}
        >
            {props.label}
        </Button>
    ) : (
        <IconButton
            disabled={!!props.disabled}
            className="MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeLarge"
            aria-label="Primary"
            onClick={props.onClick ? props.onClick : () => {}}
        >
            {renderIcon()}
        </IconButton>
    );
};
