import { gql } from "@apollo/client";

export const deleteContentMutation = gql`
    mutation deleteContent($id: ID!) {
        deleteContent(id: $id) {
            ... on TextContent {
                id
            }
        }
    }
`;
