import { gql } from "@apollo/client";
import { departmentFragment } from "../fragments/departmentFragment";

export const updateDepartmentMutation = gql`
    mutation updateDepartment($id: ID!, $input: DepartmentInput!) {
        updateDepartment(id: $id, input: $input) {
            ...Department
        }
    }
    ${departmentFragment}
`;
