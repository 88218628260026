import { gql } from "@apollo/client";
import { successStoryFragment } from "../fragments/successStoryFragment";

export const updateSuccessStoryMutation = gql`
    mutation updateSuccessStory($id: ID!, $input: SuccessStoryInput!) {
        updateSuccessStory(id: $id, input: $input) {
            ...SuccessStory
        }
    }
    ${successStoryFragment}
`;
