import { gql } from "@apollo/client";

export const attachDocumentMutation = gql`
    mutation attachDocument($contentId: ID!, $documentId: ID!) {
        attachDocument(contentId: $contentId, documentId: $documentId) {
            ... on TextContent {
                id
            }
        }
    }
`;
