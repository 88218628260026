import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { MaintenanceForm, MaintenanceFormValues, maintenanceValidator } from "./MaintenanceForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Maintenance } from "Api/graphql/admin/types";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    maintenance: Maintenance | null;
    isDeleteDialogVisible: boolean;
};

class MaintenanceEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        maintenance: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const maintenance = await Api.getMaintenanceById(this.props.match.params.id);
            this.setState({ maintenance, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.maintenanceList);
        }
    }

    private onSubmit = async (values: MaintenanceFormValues, formikHelpers: FormikHelpers<MaintenanceFormValues>): Promise<boolean> => {
        try {
            const maintenance = await Api.updateMaintenance(this.state.maintenance!.id, {
                ...values,
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.maintenance.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ maintenance });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.maintenance.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.maintenanceList} color="inherit">
                    {I18n.formatMessage({ id: "pages.maintenance.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.maintenance?.id || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteMaintenance(this.state.maintenance!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.maintenance.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ maintenance: null });
        } catch (error) {
            const errorMessage = error instanceof ApiError ? ` ${error.message}` ?? "" : "";
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.maintenance.edit.deleteError" }) + errorMessage, { variant: "error", transitionDuration: { appear: 7000 } });
        }
    };

    public render() {
        const { isLoading, maintenance } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!maintenance) {
            return <Redirect to={Path.maintenanceList} />;
        }

        const initialValues: MaintenanceFormValues = {
            active_from: maintenance.active_from ? DateUtils.format(DateUtils.parseISO(maintenance.active_from), DateFormat.input) : "",
            active_to: maintenance.active_to ? DateUtils.format(DateUtils.parseISO(maintenance.active_to), DateFormat.input) : "",
            scopes: maintenance.scopes,
            notification: maintenance.notification,
            maintenance: maintenance.maintenance,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={maintenance.id} rightButtonsComponent={<FunctionalButton onClick={this.onDeleteButtonClick} type={FunctionalButtonIcon.delete} />} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={maintenanceValidator()} enableReinitialize={true}>
                    {props => (
                        <>
                            <MaintenanceForm formType={FormType.edit} formProps={props} maintenance={maintenance} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.maintenance.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.maintenance.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.onDeleteDialogClose}
                    onFunctionClick={this.onDeleteClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(MaintenanceEditPage));
