import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, TextField, Box, Typography, FormControlLabel, Checkbox } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { RoleInput, Role, Permission, PermissionType } from "Api/graphql/admin/types";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { FullscreenLoader, PageCard, SavePanel, TitleBar } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { Helpers } from "Utils/Helpers";
import "./styles.css";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

export type RoleFormValues = RoleInput & { name?: string };

type ComponentProps = {
    formType: FormType;
    formProps: FormikProps<RoleFormValues>;
    role?: Role;
    permissions: Permission[];
    onDeleteButtonToggle?: (isDeleteDialogVisible: boolean) => void;
};

type Props = ComponentProps & RouteComponentProps;

export const RoleForm = withRouter((props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.roleList);
    }, [history]);

    const renderRightButtonComponent = () => {
        return props.formType === FormType.edit ? (
            <FunctionalButton onClick={() => (props.onDeleteButtonToggle ? props.onDeleteButtonToggle(true) : {})} type={FunctionalButtonIcon.delete} />
        ) : null;
    };

    return (
        <div>
            <TitleBar title={props.role?.title} rightButtonsComponent={renderRightButtonComponent()} />
            <Form formProps={props.formProps}>
                <PageCard.Container>
                    <Field name="title" validate={I18nHelpers.formatValidator(Validator.required)}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.role.form.titleLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                error={meta.touched && !!meta.error}
                                {...field}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    field.onChange(e);
                                    props.formProps.setFieldValue("name", Helpers.toSlug(e.currentTarget.value, true));
                                }}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    {props.formType === FormType.create ? (
                        <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.role.form.nameLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                />
                            )}
                        </Field>
                    ) : (
                        <Field name="name">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.role.form.nameLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    disabled
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                />
                            )}
                        </Field>
                    )}

                    {props.role?.id && (
                        <div>
                            <Box mt="30px" />

                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.role.form.idLabel" })}
                            </Typography>
                            <Typography variant="subtitle1">{props.role.id}</Typography>
                        </div>
                    )}

                    <Box mt="30px" />

                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.role.form.permissionsHeading" })} />

                    <Field name="permissions" validate={I18nHelpers.formatValidator(Validator.permissionLength)}>
                        {({ field, form }: FieldProps) => {
                            const onChangePermission = (permission: PermissionType) => (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                if (checked) {
                                    form.setFieldValue("permissions", [...field.value, permission], true);
                                } else {
                                    form.setFieldValue(
                                        "permissions",
                                        field.value.filter((v: PermissionType) => permission !== v),
                                        true
                                    );
                                }
                                form.setFieldTouched("permissions", true);
                            };

                            const groups = [...new Set(props.permissions.map(p => p.group))];
                            const groupsMap: { [key: string]: PermissionType[] } = {};
                            for (let i = 0; i < groups.length; i++) {
                                groupsMap[groups[i]] = props.permissions.filter(p => p.group === groups[i]).map(p => p.name);
                            }

                            const renderSelector = (group: string) => {
                                const areAllPermissionsChecked = groupsMap[group].every(p => props.formProps.values.permissions.includes(p));

                                const massSelect = (isAllSelected: boolean) => {
                                    const newPermissions = isAllSelected
                                        ? [...props.formProps.values.permissions, ...groupsMap[group].filter(p => !props.formProps.values.permissions.includes(p))]
                                        : [...props.formProps.values.permissions.filter(p => !groupsMap[group].includes(p))];

                                    props.formProps.setFieldValue("permissions", newPermissions, true);
                                };

                                return (
                                    <span className="group-select" onClick={() => massSelect(!areAllPermissionsChecked)}>
                                        {I18n.formatMessage({ id: `pages.role.form.group.select.${areAllPermissionsChecked ? "none" : "all"}` })}
                                    </span>
                                );
                            };

                            return groups.map(group => {
                                return (
                                    <div key={group}>
                                        <div className="group-wrapper">
                                            <p className="group-name">{I18n.formatMessage({ id: `pages.role.form.group.${group}` })}</p>
                                            {renderSelector(group)}
                                        </div>
                                        {props.permissions
                                            .filter(p => p.group === group)
                                            .map((permission: Permission) => {
                                                return (
                                                    <div key={permission.id}>
                                                        <FormControlLabel
                                                            style={{ paddingLeft: "15px" }}
                                                            control={
                                                                <Checkbox
                                                                    checked={props.formProps.values.permissions.includes(permission.name)}
                                                                    onChange={onChangePermission(permission.name)}
                                                                />
                                                            }
                                                            label={permission.title}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                );
                            });
                        }}
                    </Field>
                </PageCard.Container>

                <SavePanel>
                    <Grid container justify="space-between">
                        <Button variant="outlined" color="primary" onClick={onCancelClick}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button
                            type="submit"
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                        >
                            {props.formType === FormType.create ? "Létrehozás" : I18n.formatMessage({ id: "common.save" })}
                        </Button>
                    </Grid>
                </SavePanel>
                <FullscreenLoader visible={props.formProps.isSubmitting} />
            </Form>
        </div>
    );
});
