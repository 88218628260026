import { gql } from "@apollo/client";
import { pageFragment } from "../fragments/pageFragment";

export const updatePageMutation = gql`
    mutation updatePage($id: Int!, $input: PageInput!) {
        updatePage(id: $id, input: $input) {
            ...Page
        }
    }
    ${pageFragment}
`;
