import React from "react";
import { TypeFilterValue, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { Icon, IconButton, TextField, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn, DataGridUtils } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { OrderItem } from "Api/graphql/admin/types";
import { Path } from "Utils/Path";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { Button, Grid } from "@bigfish/admin-ui/core";
import { SavePanel } from "@bigfish/admin-ui/components";
import { isEqual } from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Constants } from "Utils/Constants";
import { CorrectionItem } from "./CorrectOrderItemsDialog";
import { AddCircle, RemoveCircle } from "@bigfish/admin-ui/icons";
import styled from "styled-components";

type Props = WithStyles<typeof dataGridStyles> & {
    orderItems: OrderItem[];
    selectedOrderItems: CorrectionItem[];
    isOrderEditable?: boolean;
    onModifyOrderSubmit?: (orderItems: OrderItem[]) => void;
    onItemsChange: (items: CorrectionItem[]) => void;
} & RouteComponentProps;

interface State {
    filterValues: TypeFilterValue;
    orderItems: OrderItem[];
    refreshIndex: number;
}

class CorrectionDataGrid extends React.Component<Props, State> {
    private getDataSource = () => async (): Promise<DataSource<OrderItem>> => {
        const data = this.state.orderItems.filter(orderItem => this.state.filterValues?.every(DataGridUtils.filter(orderItem)));
        return { data, count: data.length };
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            filterValues: [],
            orderItems: props.orderItems,
            refreshIndex: 0,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        if (!isEqual(prevProps.orderItems, this.props.orderItems)) {
            this.setState({ orderItems: this.props.orderItems });
        }
    }

    private buildNewItems = (idToCorrect: number, quantity: number) => {
        const newCorrecteds: CorrectionItem[] = [...this.props.selectedOrderItems];
        const indexToModify = newCorrecteds.findIndex(nc => nc.id === idToCorrect);

        if (quantity <= 0 && indexToModify !== -1) {
            /// Item is in the array and is removed
            newCorrecteds.splice(indexToModify, 1);
        } else if (indexToModify === -1) {
            // New item
            newCorrecteds.push({ id: idToCorrect, quantity });
        } else {
            // Existing item
            newCorrecteds[indexToModify] = { id: idToCorrect, quantity };
        }

        this.props.onItemsChange(newCorrecteds);
    };

    private getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "correctionQuantity",
                header: I18n.formatMessage({ id: "components.correctionDataGrid.quantityInputHeader" }),
                sortable: false,
                defaultWidth: 160,
                textAlign: "center",
                render: ({ data }: { data: OrderItem }) => {
                    const foundCorrected = this.props.selectedOrderItems.find(soi => soi.id === data.id);
                    const maxQuantity = this.props.orderItems.find(oi => oi.id === data.id)?.quantity;

                    if (maxQuantity === undefined) return null;

                    return (
                        <div>
                            <IconButton onClick={() => (foundCorrected ? this.buildNewItems(data.id, foundCorrected.quantity - 1) : {})}>
                                <RemoveCircle />
                            </IconButton>

                            <CorrectionNumberWrapper>
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    value={foundCorrected?.quantity ?? 0}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = Number.parseInt(e.currentTarget.value, 10);
                                        const clampedValue = value <= maxQuantity ? value : maxQuantity;
                                        this.buildNewItems(data.id, clampedValue || 0);
                                    }}
                                />
                            </CorrectionNumberWrapper>

                            <IconButton onClick={() => this.buildNewItems(data.id, foundCorrected && foundCorrected.quantity <= maxQuantity ? foundCorrected.quantity + 1 : 1)}>
                                <AddCircle />
                            </IconButton>
                        </div>
                    );
                },
            },
            {
                name: "product_lfdnr",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.lfdnr" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                editable: false,
            },
            {
                name: "product_name",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.productName" }),
                defaultFlex: 1,
                minWidth: 250,
                sortable: false,
                filterable: true,
                filterDelay: Constants.filterDelayMS,
                editable: false,
            },
            {
                name: "ean",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.ean" }),
                defaultFlex: 1,
                minWidth: 260,
                sortable: false,
                filterable: true,
                editable: false,
            },
            {
                name: "unit_price",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.unit_price" }),
                defaultWidth: 180,
                sortable: false,
                filterable: true,
                filterEditor: NumberFilter,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },
            {
                name: "quantity",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.quantity" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
            },
            {
                name: "quantity_delivered",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.quantity_delivered" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
            },
            {
                name: "quantity_missing",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.quantity_missing" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
            },
            {
                name: "sub_total",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.sub_total" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
                editable: false,
            },
        ];
    };

    private filterValues = (): TypeFilterValue => {
        const filterValues = [
            { name: "product_lfdnr", operator: "inrange", type: "number", value: undefined },
            { name: "product_name", operator: "contains", type: "string", value: "" },
            { name: "unit_price", operator: "inrange", type: "number", value: undefined },
            { name: "quantity", operator: "inrange", type: "number", value: undefined },
            { name: "sub_total", operator: "inrange", type: "number", value: undefined },
        ];

        return filterValues;
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.orderEdit(rowProps.data.id);
    };

    private isChanged = () => {
        return !isEqual(this.props.orderItems, this.state.orderItems);
    };

    render() {
        return (
            <div key={this.state.refreshIndex}>
                <UnControlledDataGrid<OrderItem>
                    key={this.state.refreshIndex}
                    className={this.props.classes.dataGrid}
                    rowHeight={50}
                    style={{ minHeight: 103 + this.props.orderItems.length * 50 }}
                    dataSource={this.getDataSource()}
                    columns={this.getColumns()}
                    defaultFilterValue={this.filterValues()}
                    onFilterValueChange={filterValues => this.setState({ filterValues })}
                    rowLink={this.getRowLink}
                    activeCell={null}
                    onRowClick={() => {}}
                    pagination={false}
                    editable={false}
                />
                <SavePanel>
                    <Grid container justify="space-between">
                        <Button variant="outlined" color="primary" onClick={() => this.props.history.push(Path.orderList)}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button
                            type="submit"
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!this.isChanged()}
                            onClick={e => {
                                e.preventDefault();
                                if (this.props.onModifyOrderSubmit) {
                                    this.props.onModifyOrderSubmit([...this.state.orderItems]);
                                }
                            }}
                        >
                            {I18n.formatMessage({ id: "common.save" })}
                        </Button>
                    </Grid>
                </SavePanel>
            </div>
        );
    }
}

export default withRouter(withStyles(dataGridStyles, { withTheme: true })(CorrectionDataGrid));

const CorrectionNumberWrapper = styled.span`
    input {
        width: 45px;
        height: 5px;
    }
`;
