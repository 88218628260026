import { gql } from "@apollo/client";
import { shippingMethodFragment } from "../fragments/shippingMethodFragment";

export const updateShippingMethodMutation = gql`
    mutation updateShippingMethod($id: String!, $input: ShippingMethodInput!) {
        updateShippingMethod(id: $id, input: $input) {
            ...ShippingMethod
        }
    }
    ${shippingMethodFragment}
`;
