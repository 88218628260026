import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Redirection } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { RedirectionForm, RedirectionFormValues, redirectionValidator } from "./RedirectionForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { DateUtils, DateFormat } from "Utils/DateUtils";
import { RedirectionType } from "Pages/RedirectionList/RedirectionListPage";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    redirection: Redirection | null;
    isDeleteDialogVisible: boolean;
};

class RedirectionEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        redirection: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const redirection = await Api.getRedirection(Number.parseInt(this.props.match.params.id!, 10));
            this.setState({ redirection, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.redirectionList);
        }
    }

    private toggleDeleteDialogVisibility = () => {
        this.setState({ isDeleteDialogVisible: !this.state.isDeleteDialogVisible });
    };

    private deleteRedirection = async (): Promise<void> => {
        try {
            await Api.deleteRedirection(Number.parseInt(this.state.redirection!.id, 10));
            this.props.history.push(Path.redirectionList);
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
        }
    };

    private onSubmit = async (values: RedirectionFormValues, formikHelpers: FormikHelpers<RedirectionFormValues>): Promise<boolean> => {
        try {
            const redirection = await Api.updateRedirection(Number.parseInt(this.state.redirection!.id, 10), {
                from_site_id: Number.parseInt(values.from_site_id!, 10),
                from: values.from,
                to_site_id: values.to_site_id ? Number.parseInt(values.to_site_id, 10) : undefined,
                to: values.to,
                forward: values.forward,
                is_permanent: values.is_permanent === "true",
                is_regex: values.is_regex,
                active_from: values.active_from ? DateUtils.format(DateUtils.parseISO(values.active_from), DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(DateUtils.parseISO(values.active_to), DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.redirection.edit.succeed" }), { variant: "success" });
            this.setState({ redirection });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.redirection.edit.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.redirectionList} color="inherit">
                    {I18n.formatMessage({ id: "pages.redirectionList.breadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.redirection.edit.breadcrumb" })}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, redirection } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        const getRedirectionTypeFromValues = (): RedirectionType | null => {
            const { redirection } = this.state;

            if (!redirection?.from_site?.id) return null;

            if (redirection.from_site && redirection.to_site) {
                return RedirectionType.siteToSite;
            } else if (redirection.to && redirection.to[0] === "/") {
                return RedirectionType.internal;
            } else {
                return RedirectionType.external;
            }
        };

        const redirectionType = getRedirectionTypeFromValues();

        if (!redirection || !redirectionType) {
            return <Redirect to={Path.redirectionList} />;
        }

        const initialValues: RedirectionFormValues = {
            from_site_id: redirection.from_site?.id ?? null,
            from: redirection.from ?? "",
            to_site_id: redirection.to_site?.id ?? null,
            to: redirection.to ?? "",
            forward: redirection.forward ?? null,
            is_permanent: redirection.is_permanent ? "true" : "false",
            is_regex: !!redirection.is_regex,
            active_from: redirection.active_from ? DateUtils.format(DateUtils.parseISO(redirection.active_from), DateFormat.input) : "",
            active_to: redirection.active_to ? DateUtils.format(DateUtils.parseISO(redirection.active_to), DateFormat.input) : "",
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={redirection.id} rightButtonsComponent={<FunctionalButton onClick={this.toggleDeleteDialogVisibility} type={FunctionalButtonIcon.delete} />} />
                <PageCard.Container>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        validateOnBlur={false}
                        enableReinitialize={true}
                        validate={redirectionValidator(redirectionType)}
                    >
                        {props => (
                            <>
                                <RedirectionForm formType={FormType.edit} formProps={props} redirectionType={redirectionType} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>

                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.redirection.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.redirection.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.toggleDeleteDialogVisibility}
                    onFunctionClick={this.deleteRedirection}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(RedirectionEditPage));
