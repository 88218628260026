import { gql } from "@apollo/client";
import { productListFragment } from "../fragments/productListFragment";

export const updateProductListMutation = gql`
    mutation updateProductList($id: Int!, $input: ProductListInput!) {
        updateProductList(id: $id, input: $input) {
            ...ProductList
        }
    }
    ${productListFragment}
`;
