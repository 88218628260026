import React, { useCallback } from "react";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { I18n } from "Src/i18n/I18n";
import { GalleryFormValues } from "Pages/Gallery/GalleryForm";
import { Api } from "Api/Api";
import { FullscreenLoader, TabsComponent, TitleBar } from "@bigfish/admin-ui/components";
import { Box, Button, createStyles, Divider, Grid, Icon, WithStyles, withStyles } from "@bigfish/admin-ui/core";
import { Gallery, GalleryListItem } from "Api/graphql/admin/types";
import { ModalContent } from "./CKGalleryInsertModal";
import { GalleryFormContentTab, GalleryImage } from "Pages/Gallery/GalleryFormContentTab";
import { GalleryFormOrderTab } from "Pages/Gallery/GalleryFormOrderTab";
import { FormModal } from "Components/FormModal";
import { MediaLibraryHelper } from "Utils/MediaLibraryHelper";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type ComponentProps = {
    type: ModalContent.create | ModalContent.edit;
    galleryId?: string;
    onSucceed: (gallery: GalleryListItem) => void;
    onClose: () => void;
};

type Props = ComponentProps & WithSnackbarProps & WithStyles<typeof styles>;

type State = {
    isLoading: boolean;
    gallery: Gallery | null;
    isDeleteModalVisible: boolean;
};

class CKGalleryModal extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: this.props.type === ModalContent.edit,
        gallery: null,
        isDeleteModalVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.galleryId) {
            return;
        }

        try {
            const gallery = await Api.getGallery(this.props.galleryId);
            this.setState({ gallery, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.onClose();
        }
    }

    private onSubmit = async (values: GalleryFormValues, formikHelpers: FormikHelpers<GalleryFormValues>): Promise<boolean> => {
        if (this.props.type === ModalContent.create) {
            try {
                const gallery = await Api.createGallery({ title: values.title, images: values.images });
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryModal.create.succeed" }), { variant: "success" });
                this.props.onSucceed(gallery);
                return true;
            } catch (error) {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryModal.create.failed" }), { variant: "error" });
                if (error instanceof ApiError) {
                    Form.submitFailed(formikHelpers, error);
                }
                return false;
            }
        } else {
            try {
                const gallery: GalleryListItem = await Api.updateGallery(this.state.gallery!.id, {
                    title: values.title,
                    images: values.images.map(image => ({ url: image.url, caption: image.caption, credit: image.credit })),
                });

                this.props.enqueueSnackbar(I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryModal.edit.succeed" }), { variant: "success" });
                this.props.onSucceed(gallery);
                return true;
            } catch (error) {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryModal.edit.failed" }), { variant: "error" });
                if (error instanceof ApiError) {
                    Form.submitFailed(formikHelpers, error);
                }
            }
            return false;
        }
    };

    private onDeleteClick = (): void => {
        this.setState({ isDeleteModalVisible: true });
    };

    private deleteGallery = async (): Promise<void> => {
        try {
            await Api.deleteGallery(this.props.galleryId!);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryModal.delete.succeed" }), { variant: "success" });
            this.setState({ gallery: null, isDeleteModalVisible: false }, this.props.onClose);
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryModal.delete.failed" }), { variant: "error" });
        }
    };

    public render() {
        const initialValues: GalleryFormValues = {
            title: this.state.gallery?.title || "",
            handle: "",
            images: this.state.gallery?.images || [],
        };

        return (
            <>
                <FormModal title={I18n.formatMessage({ id: `components.inputs.richTextEditor.galleryModal.${this.props.type}.title` })} open={true} onClose={this.props.onClose}>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                        {formProps => {
                            const [tab, setTab] = React.useState(0);
                            const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
                                setTab(newValue);
                            };

                            const onSelection = useCallback(
                                (urls: string[]) => {
                                    const images = [...formProps.values.images, ...urls.map((url: string): GalleryImage => ({ url, caption: "", credit: "" }))];
                                    formProps.setFieldValue("images", images);
                                },
                                [formProps]
                            );

                            return (
                                <Form formProps={formProps}>
                                    <TitleBar
                                        title=""
                                        rightButtonsComponent={
                                            <>
                                                <FunctionalButton disabled={!this.props.galleryId} onClick={this.onDeleteClick} type={FunctionalButtonIcon.delete} />
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    startIcon={<Icon className="item fa fa-plus-circle" />}
                                                    onClick={() => MediaLibraryHelper.openLibrary({ onSelection })}
                                                >
                                                    {I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryModal.addFromMediaLibrary" })}
                                                </Button>
                                            </>
                                        }
                                    />
                                    <TabsComponent
                                        ariaLabel="gallery-editor"
                                        tabs={[
                                            {
                                                label: I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryModal.tab.content" }),
                                                id: "gallery-content",
                                                content: (
                                                    <div className={this.props.classes.modalTab}>
                                                        <GalleryFormContentTab
                                                            formProps={formProps}
                                                            formType={this.props.type === ModalContent.create ? FormType.create : FormType.edit}
                                                        />
                                                    </div>
                                                ),
                                            },
                                            {
                                                label: I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryModal.tab.sort" }),
                                                id: "gallery-order",
                                                content: (
                                                    <div className={this.props.classes.modalTab}>
                                                        <GalleryFormOrderTab formProps={formProps} itemStyle={{ zIndex: 9999 }} />
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        currentTab={tab}
                                        changeTab={changeTab}
                                    />
                                    <Divider />
                                    <Box pt="30px">
                                        <Grid container justify="space-between" alignItems="center">
                                            <Box>
                                                <Button variant="outlined" size="large" color="primary" fullWidth disabled={formProps.isSubmitting} onClick={this.props.onClose}>
                                                    {I18n.formatMessage({ id: "common.cancel" })}
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    color="secondary"
                                                    fullWidth
                                                    disabled={formProps.isSubmitting || !formProps.dirty}
                                                    onClick={() => {
                                                        formProps.submitForm();
                                                    }}
                                                >
                                                    {I18n.formatMessage({ id: "common.save" })}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Box>
                                    <FullscreenLoader visible={formProps.isSubmitting || this.state.isLoading} />
                                </Form>
                            );
                        }}
                    </Formik>
                </FormModal>

                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.gallery.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.gallery.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteModalVisible}
                    onClose={() => {
                        this.setState({ isDeleteModalVisible: false });
                    }}
                    onFunctionClick={this.deleteGallery}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                />
            </>
        );
    }
}

const styles = () =>
    createStyles({
        modalTab: {
            maxHeight: "50vh",
            overflow: "scroll",
            paddingTop: 20,
            paddingBottom: 20,
        },
    });

export default withSnackbar(withStyles(styles)(CKGalleryModal));
