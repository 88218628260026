import { gql } from "@apollo/client";
import { adminFragment } from "../fragments/adminFragment";

export const updateAdminMeMutation = gql`
    mutation updateAdminMe($input: UpdateAdminMeDataInput!) {
        updateAdminMe(input: $input) {
            ...Admin
        }
    }
    ${adminFragment}
`;
