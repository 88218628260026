import { isAfter, isBefore } from "date-fns";
import { I18n } from "I18n/I18n";
import { ProductCategoryBannerInput, PermissionType } from "Api/graphql/admin/types";
import { Constants } from "./Constants";
import { DateUtils } from "./DateUtils";

export enum ValidatorMessage {
    required = "required",
    invalidEmail = "invalidEmail",
    passwordNotMatch = "passwordNotMatch",
    invalidNewPassword = "invalidNewPassword",
    invalidProfileName = "invalidProfileName",
    invalidCategoryTitle = "invalidCategoryTitle",
    invalidPermissionsLength = "invalidPermissionsLength",
    invalidGalleryImagesLength = "invalidGalleryImagesLength",
    invalidContentTitle = "invalidContentTitle",
    ogTitleTooLong = "ogTitleTooLong",
    ogDescriptionTooLong = "ogDescriptionTooLong",
    metaTitleTooLong = "metaTitleTooLong",
    metaDescriptionTooLong = "metaDescriptionTooLong",
    altTitleTooLong = "altTitleTooLong",
    bannerImageTitleTooLong = "bannerImageTitleTooLong",
    startDateCantBeInThePast = "startDateCantBeInThePast",
    invalidPersonName = "invalidPersonName",
    invalidPersonPosition = "invalidPersonPosition",
    invalidBenefitName = "invalidBenefitName",
    invalidDepartmentName = "invalidDepartmentName",
    invalidDivisionName = "invalidDivisionName",
    shippingMethodLeadTooLong = "shippingMethodLeadTooLong",
    paymentMethodDescriptionTooLong = "paymentMethodDescriptionTooLong",
    arrayCantBeEmpty = "arrayCantBeEmpty",
    redirectionToMustBeAbsoluteUrl = "redirectionToMustBeAbsoluteUrl",
    redirectionToMustBeRelativeUrl = "redirectionToMustBeRelativeUrl",
    slug = "slug",
    urlFormat = "urlFormat",
    valueMustBeANumber = "valueMustBeANumber",
}

export class Validator {
    public static readonly emailRE: RegExp = /(?:[a-z0-9!#$%&'+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    public static readonly passwordRE: RegExp = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{9,}$/;
    public static readonly numberRE: RegExp = /^\d+$/;
    public static readonly slugRE: RegExp = /^[a-zA-Z0-9*\-?=_.:%&/]+$/;
    public static readonly contentUrlRE: RegExp = /^[a-zA-Z0-9 -]+$/;

    public static required<T>(value?: T | null): ValidatorMessage | undefined {
        if (!value) {
            return ValidatorMessage.required;
        }
        return undefined;
    }

    public static mustNotBeNil<T>(value?: T | null): ValidatorMessage | undefined {
        if (value === null || value === undefined) {
            return ValidatorMessage.required;
        }
        return undefined;
    }

    public static profileName(profileName: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(profileName);
        if (requiredError) {
            return requiredError;
        }

        if (profileName.length < 3) {
            return ValidatorMessage.invalidProfileName;
        }
        return undefined;
    }

    public static email(email: string): ValidatorMessage | undefined {
        if (!email.trim() || !Validator.emailRE.test(email)) {
            return ValidatorMessage.invalidEmail;
        }
        return undefined;
    }

    /**
     * Validate new password to be at least 9 character long and contains:
     * - 1 uppercase character
     * - 1 lowercase character
     * - 1 number
     * - 1 special character
     * @param password string
     */
    public static newPassword(password: string): ValidatorMessage | undefined {
        if (!password.trim() || !Validator.passwordRE.test(password)) {
            return ValidatorMessage.invalidNewPassword;
        }

        return undefined;
    }

    public static samePassword(password: string) {
        return (passwordConfirmation: string): ValidatorMessage | undefined => {
            if (password !== passwordConfirmation) {
                return ValidatorMessage.passwordNotMatch;
            }
            return undefined;
        };
    }

    public static permissionLength(permissions: PermissionType[]): ValidatorMessage | undefined {
        if (permissions.length === 0) {
            return ValidatorMessage.invalidPermissionsLength;
        }
        return undefined;
    }

    public static galleryImagesLength(images: []) {
        if (images.length < 1) {
            return ValidatorMessage.invalidGalleryImagesLength;
        }
        return undefined;
    }

    public static contentTitle(title: string): ValidatorMessage | undefined {
        if (title.length < 3) {
            return ValidatorMessage.invalidContentTitle;
        }
        return undefined;
    }

    public static ogTitleMaxLength(titleTag: string): ValidatorMessage | undefined {
        if (titleTag?.length > Constants.ogTitleMaxLength) {
            return ValidatorMessage.ogTitleTooLong;
        }
        return undefined;
    }

    public static personName(name: string): ValidatorMessage | undefined {
        if (name.length < 3 || name.length > 255) {
            return ValidatorMessage.invalidPersonName;
        }
        return undefined;
    }

    public static ogDescriptionMaxLength(description: string): ValidatorMessage | undefined {
        if (description?.length > Constants.ogDescriptionMaxLength) {
            return ValidatorMessage.ogDescriptionTooLong;
        }
        return undefined;
    }

    public static personPosition(position: string): ValidatorMessage | undefined {
        if (position.length < 3 || position.length > 255) {
            return ValidatorMessage.invalidPersonPosition;
        }
        return undefined;
    }

    public static metaTitleMaxLength(metaTitle: string): ValidatorMessage | undefined {
        if (metaTitle?.length > Constants.metaTitleMaxLength) {
            return ValidatorMessage.metaTitleTooLong;
        }
        return undefined;
    }

    public static metaDescriptionMaxLength(metaDescription: string): ValidatorMessage | undefined {
        if (metaDescription?.length > Constants.metaDescriptionMaxLength) {
            return ValidatorMessage.metaDescriptionTooLong;
        }
        return undefined;
    }

    public static benefitName(name: string): ValidatorMessage | undefined {
        if (name.length < 3 || name.length > 255) {
            return ValidatorMessage.invalidBenefitName;
        }
        return undefined;
    }

    public static altTitleMaxLength(title: string): ValidatorMessage | undefined {
        if (title?.length > Constants.altTitleMaxLength) {
            return ValidatorMessage.altTitleTooLong;
        }
        return undefined;
    }

    public static departmentName(name: string): ValidatorMessage | undefined {
        if (name.length < 3 || name.length > 255) {
            return ValidatorMessage.invalidDepartmentName;
        }
        return undefined;
    }

    public static bannerImageTitleMaxLength(title: string): ValidatorMessage | undefined {
        if (title?.length > Constants.bannerImageTitleMaxLength) {
            return ValidatorMessage.bannerImageTitleTooLong;
        }
        return undefined;
    }

    public static divisionName(name: string): ValidatorMessage | undefined {
        if (name.length > 255) {
            return ValidatorMessage.invalidDivisionName;
        }
        return undefined;
    }

    public static startDatePast(startDate: any): ValidatorMessage | undefined {
        if (isBefore(DateUtils.parseISO(startDate), new Date())) {
            return ValidatorMessage.startDateCantBeInThePast;
        }
        return undefined;
    }

    public static endDateCantBeBeforeStart(startDate: any, endDate: any): string | undefined {
        return isBefore(DateUtils.parseISO(endDate), DateUtils.parseISO(startDate)) ? I18n.formatMessage({ id: "validator.endDateCantBeBeforeStart" }) : undefined;
    }

    public static startDateCantBeAfterEnd(startDate: any, endDate: any): string | undefined {
        return isAfter(DateUtils.parseISO(startDate), DateUtils.parseISO(endDate)) ? I18n.formatMessage({ id: "validator.startDateCantBeAfterEnd" }) : undefined;
    }

    // Used in ProductCategoryForm where we check if a banner's date conflicts with the other banner dates
    public static dateOverlaps(date: any, otherBanners: ProductCategoryBannerInput[]): string | undefined {
        if (
            otherBanners.find(otherBanner =>
                DateUtils.isBetween(DateUtils.parseISO(date), DateUtils.parseISO(otherBanner.active_from), DateUtils.parseISO(otherBanner.active_to), "[]")
            )
        ) {
            return I18n.formatMessage({ id: "validator.dateOverlaps" });
        }
        return undefined;
    }

    public static categoryTitle(name: string): ValidatorMessage | undefined {
        if (name.length < 3 || name.length > 255) {
            return ValidatorMessage.invalidCategoryTitle;
        }
        return undefined;
    }

    public static shippingMethodLead(lead: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(lead);
        if (requiredError) {
            return requiredError;
        }

        if (lead.length > Constants.shippingMethodLeadMaxLength) {
            return ValidatorMessage.shippingMethodLeadTooLong;
        }
        return undefined;
    }

    public static arrayCantBeEmpty(array: any[]): ValidatorMessage | undefined {
        if (!array || array.length < 1) {
            return ValidatorMessage.arrayCantBeEmpty;
        }
        return undefined;
    }

    public static redirectionToMustBeAbsolute(to: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(to);
        if (requiredError) {
            return requiredError;
        }

        if (to[0] === "/") {
            return ValidatorMessage.redirectionToMustBeAbsoluteUrl;
        }
        return undefined;
    }

    public static redirectionToMustBeRelative(to: string, checkRequired = true): ValidatorMessage | undefined {
        if (checkRequired) {
            const requiredError = Validator.required(to);
            if (requiredError) {
                return requiredError;
            }
        }

        if (to[0] !== "/") {
            return ValidatorMessage.redirectionToMustBeRelativeUrl;
        }
        return undefined;
    }

    public static paymentMethodDescription(description: string): ValidatorMessage | undefined {
        if (!!description && description.length > Constants.paymentMethodDescriptionMaxLength) {
            return ValidatorMessage.paymentMethodDescriptionTooLong;
        }
        return undefined;
    }

    public static mustBeNumber(value: number): ValidatorMessage | undefined {
        if (!Validator.numberRE.test(value.toString())) {
            return ValidatorMessage.required;
        }
        return undefined;
    }

    public static slug(value: string): ValidatorMessage | undefined {
        if (!Validator.slugRE.test(value)) {
            return ValidatorMessage.slug;
        }
        return undefined;
    }

    public static contentUrl(url: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(url);
        if (requiredError) {
            return requiredError;
        }

        if (!url.match(Validator.contentUrlRE)) {
            return ValidatorMessage.urlFormat;
        }
        return undefined;
    }
}
