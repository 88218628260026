import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { BenefitForm, BenefitFormValues } from "./BenefitForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class BenefitCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: BenefitFormValues, formikHelpers: FormikHelpers<BenefitFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createBenefit({ title: values.title, image: values.image });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.benefit.create.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.benefit.create.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.benefitList} />;
        }

        const initialValues: BenefitFormValues = {
            title: "",
            image: "",
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.benefitList} color="inherit">
                            {I18n.formatMessage({ id: "pages.benefitList.breadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.benefit.create.breadcrumb" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.benefit.create.title" })}
                    rightButtonsComponent={<FunctionalButton disabled type={FunctionalButtonIcon.delete} />}
                />
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={true}>
                        {props => (
                            <>
                                <BenefitForm formType={FormType.create} formProps={props} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(BenefitCreatePage));
