import { gql } from "@apollo/client";
import { fulfillmentFragment } from "../fragments/fulfillmentFragment";

export const updateFulfillmentMutation = gql`
    mutation updateFulfillment($id: Int!, $input: FulfillmentInput!) {
        updateFulfillment(id: $id, input: $input) {
            ...Fulfillment
        }
    }
    ${fulfillmentFragment}
`;
