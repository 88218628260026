import { gql } from "@apollo/client";

export const giftCardFragment = gql`
    fragment GiftCard on GiftCard {
        value
        barcode
        expire_at
        mail_to
        sent_at
    }
`;
