import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { ContentForm, ContentFormValues, ContentSaveType } from "./ContentForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { Link } from "Components/Link";
import { ContentType } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import Prompt from "Components/Prompt";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { NO_CATEGORY } from "./CategorySelect";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class ContentCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: ContentFormValues, formikHelpers: FormikHelpers<ContentFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        let saveType: ContentSaveType | undefined = values.saveType;
        let is_active = false;
        if (!saveType) {
            const isDelayed = values.activeFrom ? DateUtils.isAfter(DateUtils.parseISO(values.activeFrom), new Date()) : false;
            saveType = isDelayed ? ContentSaveType.schedule : ContentSaveType.publish;
        }

        switch (saveType) {
            case ContentSaveType.publish:
            case ContentSaveType.schedule:
                is_active = true;
                break;
        }

        try {
            const contentId = await Api.createContent({
                type: ContentType.TEXT,
                title: values.title,
                url: values.url,
                lead: values.lead || null,
                lead_image: values.leadImage || null,
                meta_image: values.metaImage || null,
                content: values.content || null,
                meta_title: values.metaTitle || null,
                meta_keywords: values.metaKeywords || null,
                meta_description: values.metaDescription || null,
                author: values.author || null,
                is_active,
                active_from: values.activeFrom ? DateUtils.format(DateUtils.parseISO(values.activeFrom), DateFormat.apiDateTime) : null,
                active_to: values.activeTo ? DateUtils.format(DateUtils.parseISO(values.activeTo), DateFormat.apiDateTime) : null,
                category_id: values.categoryId === NO_CATEGORY.id ? null : values.categoryId,
            });

            for (const document of values.documents) {
                try {
                    await Api.attachDocument(contentId, document.id);
                } catch (error) {
                    this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.content.form.attachDocumentFailed" }, { document: document.file_name }), { variant: "error" });
                }
            }
            this.props.enqueueSnackbar(I18n.formatMessage({ id: `pages.content.save.succeed.${saveType}` }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: `pages.content.save.failed.${saveType}` }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.contentList} />;
        }

        const initialValues: ContentFormValues = {
            url: "",
            title: "",
            lead: "",
            leadImage: "",
            metaImage: "",
            documents: [],
            content: "",
            metaTitle: "",
            metaKeywords: "",
            metaDescription: "",
            author: "",
            isActive: false,
            activeFrom: null,
            activeTo: null,
            categoryId: NO_CATEGORY.id,
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.contentList} color="inherit">
                            {I18n.formatMessage({ id: "pages.contentList.breadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.content.create.breadcrumb" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.content.create.title" })}
                    rightButtonsComponent={<FunctionalButton disabled type={FunctionalButtonIcon.delete} />}
                />
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false}>
                        {props => (
                            <>
                                <ContentForm formType={FormType.create} formProps={props} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(ContentCreatePage));
