import { ApolloClient, ApolloError, ApolloQueryResult, InMemoryCache, QueryOptions, FetchResult, MutationOptions, createHttpLink, DocumentNode } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApiError, ApiErrorMessage, FieldError } from "Api/ApiError";
import { GraphQLError } from "graphql";
import { store } from "Redux/store";
import { AuthUtils } from "Redux/utils/authUtils";

export type OnProgress = (progress: number) => void;

interface GraphQLFileUploadOptions<V> {
    mutation: DocumentNode;
    variables?: V;
    file: File;
    onProgress?: OnProgress;
}

export class GraphQLClient {
    private static readonly authHttpLink = createHttpLink({
        uri: `${process.env.REACT_APP_API_HOST}/auth/graphql`,
    });

    private static readonly adminHttpLink = createHttpLink({
        uri: `${process.env.REACT_APP_API_HOST}/admin/graphql`,
    });

    private static readonly authLink = setContext((_, prevContext) => {
        const authToken = store.getState().auth.authToken;
        if (!authToken) {
            return prevContext;
        }

        return {
            ...prevContext,
            headers: {
                ...prevContext.headers,
                authorization: `Bearer ${authToken}`,
            },
        };
    });

    public static readonly client = new ApolloClient({
        link: GraphQLClient.authLink.split(
            operation => {
                return operation.getContext().client === "auth";
            },
            GraphQLClient.authHttpLink,
            GraphQLClient.adminHttpLink
        ),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "network-only",
                errorPolicy: "all",
            },
        },
    });

    public static async mutate<R, V = {}>(options: MutationOptions<R, V>): Promise<R> {
        try {
            const response = await GraphQLClient.client.mutate<R, V>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            throw GraphQLClient.handleError(error);
        }
    }

    public static async query<R, V = {}>(options: QueryOptions<V>): Promise<R> {
        try {
            const response = await GraphQLClient.client.query<R>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            throw GraphQLClient.handleError(error);
        }
    }

    public static upload<R, V>(options: GraphQLFileUploadOptions<V>): Promise<R> {
        return new Promise((resolve: (response: R) => void, reject: (error: Error) => void) => {
            const xhr = new XMLHttpRequest();
            const body = new FormData();
            if (!options.mutation.loc) {
                reject(new Error("options.mutation.loc not found!"));
            }
            body.append("operations", JSON.stringify({ query: options.mutation.loc!.source.body, variables: options.variables }));
            body.append("map", JSON.stringify({ 0: ["variables.file"] }));
            body.append("0", options.file);

            xhr.onerror = () => {
                reject(new ApiError("ApiErrorCode.NETWORK_ERROR"));
            };

            xhr.ontimeout = () => {
                reject(new ApiError("ApiErrorCode.REQUEST_TIMEOUT"));
            };

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    try {
                        const response: { data: R; errors?: GraphQLError[] } = JSON.parse(xhr.response);
                        if (response.errors) {
                            reject(GraphQLClient.handleError(new ApolloError({ graphQLErrors: response.errors })));
                            return;
                        }
                        resolve(response.data);
                    } catch (error) {
                        reject(new ApiError("ApiErrorCode.INVALID_RESPONSE"));
                    }
                }
            };

            xhr.open("POST", `${process.env.REACT_APP_API_HOST}/admin/graphql`, true);
            const authToken = store.getState().auth.authToken;
            if (authToken) {
                xhr.setRequestHeader("authorization", `Bearer ${authToken}`);
            }
            xhr.setRequestHeader("Accept", "*/*");

            if (options.onProgress) {
                xhr.upload.onprogress = GraphQLClient.onProgress(options.onProgress);
            }

            xhr.send(body);
        });
    }

    private static onProgress = (onProgressFunction: (progress: number) => void): ((this: XMLHttpRequest, ev: ProgressEvent) => void) => {
        return function (this: XMLHttpRequest, event: ProgressEvent): void {
            onProgressFunction((event.loaded / event.total) * 100);
        };
    };

    // TODO: fix lint
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static getResult<R>(response: ApolloQueryResult<R> | FetchResult<R> | any): R {
        if (response.errors?.length > 0) {
            throw GraphQLClient.handleError(response.errors[0]);
        }

        return response.data;
    }

    private static handleError(error: ApolloError | Error): Error {
        if (
            error.message === ApiErrorMessage.unauthenticated ||
            error.message.startsWith(ApiErrorMessage.unexpectedToken) ||
            (error instanceof ApolloError && error.graphQLErrors[0].message === ApiErrorMessage.unauthenticated)
        ) {
            AuthUtils.logout(true);
        }

        if (error instanceof ApolloError && error.graphQLErrors?.length > 0) {
            const currentError = error.graphQLErrors[0];
            let formErrors: FieldError[] = [];
            if (currentError.extensions?.validation) {
                if (currentError.extensions.validation.input) {
                    formErrors = Object.keys(currentError.extensions.validation.input).map((key: string) => {
                        const parts = key.split(".");
                        const inputKey = parts[parts.length - 1];
                        return { name: inputKey, message: currentError.extensions?.validation.input[key][0] };
                    });
                } else {
                    formErrors = Object.keys(currentError.extensions.validation).map((key: string) => {
                        const parts = key.split(".");
                        const inputKey = parts[parts.length - 1];
                        return { name: inputKey, message: currentError.extensions?.validation[key][0] };
                    });
                }
            }

            if (currentError.message === ApiErrorMessage.unauthenticated) {
                AuthUtils.logout(true);
            }

            const message = currentError.message ? GraphQLClient.parseErrorMessageFromGraphQLError(currentError.message) : "unknown";
            return new ApiError(message, formErrors);
        } else {
            return new ApiError(error.message);
        }
    }

    private static parseErrorMessageFromGraphQLError(gqlError: string) {
        const regex = /(\[.*\]\s*)?(.+)/;
        const result = regex.exec(gqlError);
        if (result === null || result.length !== 3 || typeof result[2] === "undefined") {
            return gqlError;
        }
        return result[2];
    }

    private static snakeToCamel(str: string) {
        return str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));
    }
}
