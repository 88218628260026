export class NumberUtils {
    public static formatPrice(number: number | undefined | null) {
        return !number ? "-" : number.toLocaleString("hu-HU");
    }
    public static isStringNumber(value: string): boolean {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return !isNaN(value);
    }
}
