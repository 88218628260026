import { gql } from "@apollo/client";
import { adminFragment } from "../fragments/adminFragment";

export const updateAdminMutation = gql`
    mutation updateAdmin($id: Int!, $input: AdminInput!) {
        updateAdmin(id: $id, input: $input) {
            ...Admin
        }
    }
    ${adminFragment}
`;
