import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { BannerForm, BannerFormValues, bannerValidator } from "./BannerForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Banner } from "Api/graphql/admin/types";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    banner: Banner | null;
    isDeleteDialogVisible: boolean;
};

class BannerEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        banner: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const banner = await Api.getBannerById(Number.parseInt(this.props.match.params.id, 10));
            this.setState({ banner, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.bannerList);
        }
    }

    private onSubmit = async (values: BannerFormValues, formikHelpers: FormikHelpers<BannerFormValues>): Promise<boolean> => {
        try {
            const banner = await Api.updateBanner(this.state.banner!.id, {
                ...values,
                urls: values.urls.filter(u => !!u),
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.banner.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ banner });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.banner.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.bannerList} color="inherit">
                    {I18n.formatMessage({ id: "pages.banner.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.banner?.name || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteBanner(this.state.banner!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.content.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ banner: null });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.content.edit.deleteError" }), { variant: "error" });
        }
    };

    public render() {
        const { isLoading, banner } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!banner) {
            return <Redirect to={Path.bannerList} />;
        }

        const initialValues: BannerFormValues = {
            name: banner.name,
            title: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.title : undefined,
            slider_title: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.slider_title : undefined,
            type: banner.type,
            image: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.image : undefined,
            image_align: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.image_align : undefined,
            image_title: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.image_title : undefined,
            image_mobile: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.image_mobile : undefined,
            image_mobile_title: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.image_mobile_title : undefined,
            button_title: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.button_title : undefined,
            target_url: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.target_url : undefined,
            target_popup: banner.__typename === "BannerNormal" || banner.__typename === "BannerProductList" ? banner.target_popup : undefined,
            urls: banner.urls.length > 0 ? banner.urls : [""],
            is_active: banner.is_active,
            active_from: banner.active_from ? DateUtils.format(DateUtils.parseISO(banner.active_from), DateFormat.input) : "",
            active_to: banner.active_to ? DateUtils.format(DateUtils.parseISO(banner.active_to), DateFormat.input) : "",
            product_id: banner.__typename === "BannerProduct" ? banner.product.id : undefined,
            position: banner.__typename === "BannerProduct" ? banner.position : undefined,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={banner.name} rightButtonsComponent={<FunctionalButton onClick={this.onDeleteButtonClick} type={FunctionalButtonIcon.delete} />} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={bannerValidator(FormType.edit)} enableReinitialize={true}>
                    {props => (
                        <>
                            <BannerForm formType={FormType.edit} formProps={props} banner={banner} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.banner.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.banner.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.onDeleteDialogClose}
                    onFunctionClick={this.onDeleteClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(BannerEditPage));
