import { gql } from "@apollo/client";
import { benefitFragment } from "../fragments/benefitFragment";

export const updateBenefitMutation = gql`
    mutation updateBenefit($id: ID!, $input: BenefitInput!) {
        updateBenefit(id: $id, input: $input) {
            ...Benefit
        }
    }
    ${benefitFragment}
`;
