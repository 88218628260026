import { gql } from "@apollo/client";
import { productFragment } from "../fragments/productFragment";

export const createProductBundleMutation = gql`
    mutation createProductBundle($input: ProductBundleInput!) {
        createProductBundle(input: $input) {
            ...Product
        }
    }
    ${productFragment}
`;
