import { OrderStatusEnum, TodoStatus, PageBlockType, RossmannPlusVipLevel, ClientType } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";

export enum DeviceView {
    mobile = "mobile",
    tablet = "tablet",
    desktop = "desktop",
}

export enum ShippingMethodId {
    mpl_delivery = "mpl_delivery",
    mpl_pickup = "mpl_pickup",
    rossmann_delivery = "rossmann_delivery",
    rossmann_shop = "rossmann_shop",
    foxpost = "foxpost",
    gls_pickup = "gls_pickup",
    sameday_easybox = "sameday_easybox",
    gls_delivery = "gls_delivery",
}

export class Constants {
    public static readonly altTitleMaxLength = 50;
    public static readonly ogTitleMaxLength = 60;
    public static readonly ogDescriptionMaxLength = 50;
    public static readonly metaTitleMaxLength = 60;
    public static readonly metaDescriptionMaxLength = 160;
    public static readonly bannerImageTitleMaxLength = 50;
    public static readonly shippingMethodLeadMaxLength = 300;
    public static readonly paymentMethodDescriptionMaxLength = 300;
    public static readonly filterDelayMS = 500;

    public static readonly columnWidth = {
        id: 115,
        shortText: 200,
        longText: 400,
        statusIcon: 140,
        boolean: 120,
        dateTime: 290,
    };

    public static getModalPageStyles = () => {
        return { modalWidth: 1440, modalHeight: 900, backgroundColor: "#F7F9FF" };
    };

    public static readonly getOrderStatusTitle = (type: OrderStatusEnum | string) => {
        switch (type) {
            case OrderStatusEnum.open:
                return I18n.formatMessage({ id: "enums.orderStatusEnum.open" });
            case OrderStatusEnum.in_progress:
                return I18n.formatMessage({ id: "enums.orderStatusEnum.in_progress" });
            case OrderStatusEnum.completed:
                return I18n.formatMessage({ id: "enums.orderStatusEnum.completed" });
            case OrderStatusEnum.canceled:
                return I18n.formatMessage({ id: "enums.orderStatusEnum.canceled" });
            case OrderStatusEnum.modified:
                return I18n.formatMessage({ id: "enums.orderStatusEnum.modified" });
            default:
                return "";
        }
    };

    public static readonly getShippingMethodTitle = (title: string | null) => {
        switch (title) {
            case "mpl_delivery":
            case "mpl_pickup":
            case "rossmann_delivery":
            case "rossmann_shop":
                return I18n.formatMessage({ id: `pages.user.form.ordersDataGrid.select.shippingMethod.${title}` });
            default:
                return "";
        }
    };

    public static readonly getPaymentMethodTitle = (title: string | null) => {
        switch (title) {
            case "bank_transfer":
            case "barion":
            case "cod":
            case "simplepay":
                return I18n.formatMessage({ id: `pages.user.form.ordersDataGrid.select.paymentMethod.${title}` });
            default:
                return "";
        }
    };

    public static readonly getPreviewWidth = (deviceView: DeviceView): number => {
        switch (deviceView) {
            case DeviceView.mobile:
                return 375;
            case DeviceView.tablet:
                return 768;
            case DeviceView.desktop:
            default:
                return 1440;
        }
    };

    public static webshopPreviewUrls = {
        page: "/TODO",
    };

    public static readonly getTodoStatus = (status: TodoStatus) => {
        switch (status) {
            case TodoStatus.todo:
                return I18n.formatMessage({ id: `enums.todoStatus.${status}` });
            default:
                return "";
        }
    };

    public static getPageBlockTypeTitle = (pageBlockType: PageBlockType) => {
        switch (pageBlockType) {
            case PageBlockType.banner:
                return I18n.formatMessage({ id: "enums.pageBlockType.banner" });
            case PageBlockType.brand_list:
                return I18n.formatMessage({ id: "enums.pageBlockType.brand_list" });
            case PageBlockType.html:
                return I18n.formatMessage({ id: "enums.pageBlockType.html" });
            case PageBlockType.newsletter:
                return I18n.formatMessage({ id: "enums.pageBlockType.newsletter" });
            case PageBlockType.product_list:
                return I18n.formatMessage({ id: "enums.pageBlockType.product_list" });
            case PageBlockType.recommended_products:
                return I18n.formatMessage({ id: "enums.pageBlockType.recommended_products" });
            case PageBlockType.homebox:
                return I18n.formatMessage({ id: "enums.pageBlockType.homebox" });
            default:
                return "";
        }
    };

    public static fulfillment = "fulfillment";

    public static getRossmannPlusVipLevelTitle = (level: string) => {
        switch (level) {
            case RossmannPlusVipLevel.NONE:
            case RossmannPlusVipLevel.BASIC:
            case RossmannPlusVipLevel.BRONZE:
            case RossmannPlusVipLevel.SILVER:
            case RossmannPlusVipLevel.GOLD:
                return I18n.formatMessage({ id: `enums.rossmannPlusVipLevel.${level}` });
            default:
                return "";
        }
    };

    public static getClientTypeLabel = (clientType: ClientType) => {
        switch (clientType) {
            case ClientType.desktop:
            case ClientType.mobile:
            case ClientType.android:
            case ClientType.ios:
                return I18n.formatMessage({ id: `enums.clientType.${clientType}` });
            default:
                return "";
        }
    };

    public static color = {
        lightYellow: "#FFFFCC",
        lightGreen: "#CCFFCC",
        lightRed: "#FFCCCC",
    };

    public static nullFormValue: "null" = "null";
}
