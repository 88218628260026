import { gql } from "@apollo/client";
import { bannerFragment } from "../fragments/bannerFragment";

export const updateBannerMutation = gql`
    mutation updateBanner($id: Int!, $input: BannerInput!) {
        updateBanner(id: $id, input: $input) {
            ...Banner
        }
    }
    ${bannerFragment}
`;
