import { gql } from "@apollo/client";
import { menuFragment } from "../fragments/menuFragment";

export const updateMenuMutation = gql`
    mutation updateMenu($id: Int!, $input: MenuInput!) {
        updateMenu(id: $id, input: $input) {
            ...Menu
        }
    }
    ${menuFragment}
`;
