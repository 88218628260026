import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { RedirectionForm, RedirectionFormValues, redirectionValidator } from "./RedirectionForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { DateUtils, DateFormat } from "Utils/DateUtils";
import { RedirectionType } from "Pages/RedirectionList/RedirectionListPage";
import { ObjectUtils } from "Utils/ObjectUtils";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type RouteParams = {
    type: string;
};

type Props = RouteComponentProps<RouteParams> & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class RedirectionCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: RedirectionFormValues, formikHelpers: FormikHelpers<RedirectionFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            const { type } = this.props.match.params;

            await Api.createRedirection({
                from_site_id: Number.parseInt(values.from_site_id!, 10),
                from: values.from,
                to_site_id: type === RedirectionType.internal || type === RedirectionType.external ? undefined : Number.parseInt(values.to_site_id!, 10),
                to: values.to,
                forward: type === RedirectionType.internal || type === RedirectionType.external ? undefined : values.forward,
                is_permanent: values.is_permanent === "true",
                is_regex: values.is_regex,
                active_from: values.active_from ? DateUtils.format(DateUtils.parseISO(values.active_from), DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(DateUtils.parseISO(values.active_to), DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.redirection.create.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.redirection.create.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    private isTypeParamInvalid = (): boolean => {
        return (
            !this.props.match.params.type ||
            !ObjectUtils.enumAsArray<RedirectionType>(RedirectionType)
                .map(type => `${type.toLowerCase()}`)
                .includes(this.props.match.params.type)
        );
    };

    public render() {
        if (this.state.isCreateSucceed || this.isTypeParamInvalid()) {
            return <Redirect to={Path.redirectionList} />;
        }

        const initialValues: RedirectionFormValues = {
            from_site_id: null,
            from: "",
            to_site_id: null,
            to: "",
            forward: null,
            is_permanent: "true",
            is_regex: false,
            active_from: "",
            active_to: "",
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.redirectionList} color="inherit">
                            {I18n.formatMessage({ id: "pages.redirectionList.breadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.redirection.create.breadcrumb" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.redirection.create.title" })}
                    rightButtonsComponent={<FunctionalButton disabled type={FunctionalButtonIcon.delete} />}
                />
                <PageCard.Container>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        validateOnBlur={true}
                        validate={redirectionValidator(this.props.match.params.type as RedirectionType)}
                    >
                        {props => (
                            <>
                                <RedirectionForm formType={FormType.create} formProps={props} redirectionType={this.props.match.params.type as RedirectionType} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(RedirectionCreatePage));
