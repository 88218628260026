import { gql } from "@apollo/client";

export const giftCardOrderSimpleFragment = gql`
    fragment GiftCardOrderSimple on GiftCardOrderSimple {
        id
        user_fullname
        user_email
        payment_method_name
        payment_status_title
        grand_total
        created_at
        updated_at
        billing_fullname
        status {
            id
            title
        }
        user_agent_type
    }
`;
