import { gql } from "@apollo/client";
import { productCategoryFragment } from "../fragments/productCategoryFragment";

export const updateProductCategoryMutation = gql`
    mutation updateProductCategory($id: Int!, $input: ProductCategoryUpdateInput!) {
        updateProductCategory(id: $id, input: $input) {
            ...ProductCategory
        }
    }
    ${productCategoryFragment}
`;
