import React from "react";
import { PageLayout } from "Components/PageLayout";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { I18n } from "I18n/I18n";
import { Api } from "Api/Api";
import { ShippingMethodTabItem } from "Api/graphql/admin/types";
import { Loading } from "Components/Loading";
import { RouteComponentProps } from "react-router";
import { Path } from "Utils/Path";
import ShippingMethodEditPage from "./ShippingMethodEditPage";
import { Box } from "@bigfish/admin-ui/core";
import { ApiError } from "Api/ApiError";
import { Constants } from "Utils/Constants";
import { ScrollableTabsComponent } from "Components/ScrollableTabsComponent";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    shippingMethods: ShippingMethodTabItem[];
    currentTab: number;
    isLoading: boolean;
};

class ShippingMethodListPage extends React.Component<Props, State> {
    public readonly state: State = {
        shippingMethods: [],
        currentTab: 0,
        isLoading: true,
    };

    public async componentDidMount(): Promise<void> {
        this.setState({ isLoading: true }, async () => {
            try {
                const { data: shippingMethods } = await Api.listShippingMethods({ first: 100 });

                this.setState({ shippingMethods }, () => {
                    if (shippingMethods.length > 0) {
                        const currentTab = this.state.shippingMethods.findIndex(sm => sm.id === this.props.match.params.id);
                        this.setState({ currentTab: currentTab !== -1 ? currentTab : 0 }, () => {
                            this.props.history.push(Path.shippingMethodEdit(this.props.match.params.id ?? shippingMethods[0].id));
                        });
                    }
                });
            } catch (error) {
                if (error instanceof ApiError) {
                    this.props.enqueueSnackbar(error.message, { variant: "error" });
                }
            }
        });
        this.setState({ isLoading: false });
    }

    public render() {
        const { isLoading, shippingMethods, currentTab } = this.state;
        const { id } = this.props.match.params;

        const tabs = [
            ...shippingMethods,
            { __typename: "ShippingMethod", id: Constants.fulfillment, name: I18n.formatMessage({ id: "pages.shippingMethodList.fulfillment.label" }) },
        ].map(sm => {
            return {
                label: sm.name,
                id: sm.id,
                content: id && id !== Constants.fulfillment ? <ShippingMethodEditPage key={id} id={id} /> : null,
            };
        });

        const changeTab = (_event: React.ChangeEvent<{}>, newTab: number) => {
            const tabId = tabs[newTab].id;

            if (tabId !== Constants.fulfillment) {
                this.setState({ currentTab: newTab }, () => {
                    this.props.history.push(Path.shippingMethodEdit(tabId));
                });
            }
            this.props.history.push(Path.shippingMethodEdit(tabId));
        };

        if (isLoading) {
            return <Loading />;
        }

        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.shippingMethodList.title" })} />
                <PageCard.Container>
                    <ScrollableTabsComponent ariaLabel="shipping-method-list-page-tabs" tabs={tabs} currentTab={currentTab} changeTab={changeTab} />
                </PageCard.Container>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(ShippingMethodListPage);
