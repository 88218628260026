import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import {
    FulfillmentFilters,
    FulfillmentSort,
    FulfillmentListItem,
    FulfillmentSortField,
    ShippingMethodTabItem,
    RossmannPlusVipLevel,
    ShippingMethodType,
    PermissionType,
} from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { I18n } from "I18n/I18n";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { IsActiveIcon } from "Components/IsActive";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type ComponentProps = {
    shippingMethods: ShippingMethodTabItem[];
};

type Props = ComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles> & ReduxProps;

class FulfillmentListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.id" }), defaultWidth: 100, filterDelay: Constants.filterDelayMS },
            {
                name: "active_from",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.active_from" }),
                defaultFlex: 1,
                minWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: FulfillmentListItem }) => {
                    return <div>{data.active_from ? DateUtils.format(data.active_from, DateFormat.apiDateTime) : ""}</div>;
                },
            },
            {
                name: "active_to",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.active_to" }),
                defaultFlex: 1,
                minWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: FulfillmentListItem }) => {
                    return <div>{data.active_to ? DateUtils.format(data.active_to, DateFormat.apiDateTime) : ""}</div>;
                },
            },
            {
                name: "is_active",
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.is_active" }),
                defaultWidth: Constants.columnWidth.statusIcon,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.yes" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.no" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: FulfillmentListItem }) => <IsActiveIcon value={data.is_active} />,
                sortable: false,
            },
            {
                name: "date_reset_next",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.date_reset_next" }),
                defaultFlex: 1,
                minWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: FulfillmentListItem }) => {
                    return <div>{data.date_reset_next ? DateUtils.format(data.date_reset_next, DateFormat.apiDateTime) : ""}</div>;
                },
            },
            {
                name: "limit_rossmann_a2",
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.limit_rossmann_a2" }),
                defaultFlex: 1,
                minWidth: 160,
                filterEditor: NumberFilter,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "limit_dhl",
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.limit_dhl" }),
                defaultFlex: 1,
                minWidth: 160,
                filterEditor: NumberFilter,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "count_rossmann_a2",
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.count_rossmann_a2" }),
                defaultFlex: 1,
                minWidth: 230,
                filterEditor: NumberFilter,
                filterDelay: Constants.filterDelayMS,
                sortable: false,
            },
            {
                name: "count_dhl",
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.count_dhl" }),
                defaultFlex: 1,
                minWidth: 230,
                filterEditor: NumberFilter,
                filterDelay: Constants.filterDelayMS,
                sortable: false,
            },
            {
                name: "timewindow_allowed",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.timewindow_allowed" }),
                defaultWidth: 120,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.yes" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.no" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: FulfillmentListItem }) => <IsActiveIcon value={data.timewindow_allowed} />,
            },
            {
                name: "shipping_methods",
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.shipping_methods" }),
                defaultWidth: 292,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.props.shippingMethods.map(sm => {
                        return { id: sm.id, label: sm.name };
                    }),
                },
                sortable: false,
                render: ({ data }: { data: FulfillmentListItem }) => data.shipping_methods.map(sm => this.props.shippingMethods.find(psm => psm.id === sm)?.name ?? "").join(", "),
            },
            {
                name: "vip_levels",
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.vip_levels" }),
                defaultWidth: 292,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: [RossmannPlusVipLevel.NONE, RossmannPlusVipLevel.BASIC, RossmannPlusVipLevel.BRONZE, RossmannPlusVipLevel.SILVER, RossmannPlusVipLevel.GOLD].map(
                        level => {
                            return { id: level, label: Constants.getRossmannPlusVipLevelTitle(level) };
                        }
                    ),
                },
                sortable: false,
                render: ({ data }: { data: FulfillmentListItem }) => data.vip_levels.map(level => Constants.getRossmannPlusVipLevelTitle(level)).join(", "),
            },
            {
                name: "created_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.created_at" }),
                defaultFlex: 1,
                minWidth: 357,
                dateFormat: "YYYY-MM-DD",
                render: ({ data }: { data: FulfillmentListItem }) => {
                    return <div>{data.created_at ? DateUtils.format(data.created_at, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "updated_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.fulfillmentList.grid.column.updated_at" }),
                defaultFlex: 1,
                minWidth: 357,
                dateFormat: "YYYY-MM-DD",
                render: ({ data }: { data: FulfillmentListItem }) => {
                    return <div>{data.updated_at ? DateUtils.format(data.updated_at, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "active_from", operator: "inrange", type: "date" },
        { name: "active_to", operator: "inrange", type: "date" },
        { name: "limit_rossmann_a2", operator: "inrange", type: "number" },
        { name: "limit_dhl", operator: "inrange", type: "number" },
        { name: "timewindow_allowed", operator: "eq", type: "select" },
        { name: "shipping_methods", operator: "inlist", type: "select" },
        { name: "vip_levels", operator: "inlist", type: "select" },
        { name: "created_at", operator: "inrange", type: "date" },
        { name: "updated_at", operator: "inrange", type: "date" },
        { name: "is_active", operator: "eq", type: "select" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            active_from: QueryParserType.dateRange,
            active_to: QueryParserType.dateRange,
            limit_rossmann_a2: QueryParserType.integerRange,
            limit_dhl: QueryParserType.integerRange,
            timewindow_allowed: QueryParserType.boolean,
            shipping_methods: QueryParserType.enum,
            vip_levels: QueryParserType.enum,
            created_at: QueryParserType.dateRange,
            updated_at: QueryParserType.dateRange,
            is_active: QueryParserType.boolean,
        },
        sortField: FulfillmentSortField,
    });

    private sortFieldToSortOrder = {
        [FulfillmentSortField.id]: "id",
        [FulfillmentSortField.active_from]: "active_from",
        [FulfillmentSortField.active_to]: "active_to",
        [FulfillmentSortField.limit_rossmann_a2]: "limit_rossmann_a2",
        [FulfillmentSortField.limit_dhl]: "limit_dhl",
        [FulfillmentSortField.timewindow_allowed]: "timewindow_allowed",
        [FulfillmentSortField.created_at]: "created_at",
        [FulfillmentSortField.updated_at]: "updated_at",
        [FulfillmentSortField.is_active]: "is_active",
    };

    private dataSource = async (props: DataGridParams<FulfillmentSort, FulfillmentFilters>): Promise<DataSource<FulfillmentListItem>> => {
        try {
            const result = await Api.listFulfillment({
                first: props.limit,
                page: props.page,
                filters: {
                    id: props.filters?.id,
                    active_from: props.filters?.active_from,
                    active_to: props.filters?.active_to,
                    is_active: props.filters?.is_active,
                    timewindow_allowed: props.filters?.timewindow_allowed,
                    created_at: props.filters?.created_at,
                    updated_at: props.filters?.updated_at,
                    shipping_methods: props.filters?.shipping_methods ? `${props.filters?.shipping_methods}`.split(",").map(sm => sm as ShippingMethodType) : undefined,
                    vip_levels: props.filters?.vip_levels ? `${props.filters?.vip_levels}`.split(",").map(level => level as RossmannPlusVipLevel) : undefined,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    limit_rossmann_a2: props.filters?.limit_rossmann_a2
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.limit_rossmann_a2.start, 10), max: Number.parseInt(props.filters.limit_rossmann_a2.end, 10) }
                        : undefined,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    limit_dhl: props.filters?.limit_dhl
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.limit_dhl.start, 10), max: Number.parseInt(props.filters.limit_dhl.end, 10) }
                        : undefined,
                },
                sortBy: props.sortBy,
            });

            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.fulfillmentEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
                onRowClick={this.props.isSuperadmin || this.props.permissions.includes(PermissionType.website_notification_update) ? undefined : () => {}}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(FulfillmentListDataGrid)));
