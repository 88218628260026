import { PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { FulfillmentForm, FulfillmentFormValues, fulfillmentValidator } from "./FulfillmentForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import Prompt from "Components/Prompt";
import { Fulfillment, PackageType, ShippingMethodTabItem, ShippingMethodType } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Box, Typography } from "@bigfish/admin-ui/core";
import { Constants } from "Utils/Constants";
import { StatisticsPaper } from "Components/StatisticsPaper";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";
import styled from "styled-components";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { ScrollableTabsComponent } from "Components/ScrollableTabsComponent";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    fulfillment: Fulfillment | null;
    isDeleteDialogVisible: boolean;
    shippingMethods: ShippingMethodTabItem[];
};

class FulfillmentEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        fulfillment: null,
        isDeleteDialogVisible: false,
        shippingMethods: [],
    };

    public async componentDidMount(): Promise<void> {
        const { id } = this.props.match.params;

        if (!id) return;

        let fulfillment = null;
        this.setState({ isLoading: true }, async () => {
            try {
                // Fulfillment
                fulfillment = await Api.getFulfillmentById(Number.parseInt(id, 10));
                this.setState({ fulfillment });

                // Shipping methods
                const { data: shippingMethods } = await Api.listShippingMethods({ first: 100 });
                this.setState({ shippingMethods });
            } catch (error) {
                if (error instanceof ApiError) {
                    this.props.enqueueSnackbar(error.message, { variant: "error" });
                }
                this.props.history.push(Path.fulfillmentList);
            }
            this.setState({ isLoading: false });
        });
    }

    private onSubmit = async (values: FulfillmentFormValues, formikHelpers: FormikHelpers<FulfillmentFormValues>): Promise<boolean> => {
        try {
            const fulfillment = await Api.updateFulfillment(this.state.fulfillment!.id, {
                ...values,
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
                package_type_max: `${values.package_type_max}` === Constants.nullFormValue ? null : (values.package_type_max as PackageType | null),
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.fulfillment.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ fulfillment });
            return true;
        } catch (error) {
            let errorMessage = null;

            if (error instanceof ApiError) {
                errorMessage = error?.message;
                Form.submitFailed(formikHelpers, error);
            }

            this.props.enqueueSnackbar(errorMessage || I18n.formatMessage({ id: "pages.fulfillment.onEditSubmit.error" }), { variant: "error" });
        }
        return false;
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteFulfillment(this.state.fulfillment!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.fulfillment.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ fulfillment: null });
        } catch (error) {
            const errorMessage = error instanceof ApiError ? ` ${error.message}` ?? "" : "";
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.fulfillment.edit.deleteError" }) + errorMessage, {
                variant: "error",
                transitionDuration: { appear: 7000 },
            });
        }
    };

    public render() {
        const { isLoading, fulfillment, shippingMethods } = this.state;

        const tabs = [
            ...shippingMethods,
            { __typename: "ShippingMethod", id: Constants.fulfillment, name: I18n.formatMessage({ id: "pages.shippingMethodList.fulfillment.label" }) },
        ].map(sm => {
            return {
                label: sm.name,
                id: sm.id,
                content: null,
            };
        });

        const changeTab = (_event: React.ChangeEvent<{}>, newTab: number) => {
            const tabId = tabs[newTab].id;

            this.props.history.push(Path.shippingMethodEdit(tabId));
        };

        if (isLoading) {
            return <Loading />;
        }

        if (!fulfillment) {
            return <Redirect to={Path.fulfillmentList} />;
        }

        const initialValues: FulfillmentFormValues = {
            active_from: fulfillment.active_from ? DateUtils.format(DateUtils.parseISO(fulfillment.active_from), DateFormat.apiDateTime) : "",
            active_to: fulfillment.active_to ? DateUtils.format(DateUtils.parseISO(fulfillment.active_to), DateFormat.apiDateTime) : "",
            limit_rossmann_a2: fulfillment.limit_rossmann_a2,
            notification_threshold: fulfillment.notification_threshold,
            limit_dhl: fulfillment.limit_dhl,
            quantity_max: fulfillment.quantity_max,
            package_type_max: fulfillment.package_type_max ?? Constants.nullFormValue,
            shipping_methods: fulfillment.shipping_methods as ShippingMethodType[],
            timewindow_allowed: fulfillment.timewindow_allowed,
            vip_levels: fulfillment.vip_levels,
            is_active: fulfillment.is_active,
            product_id_a2_force: fulfillment.product_id_a2_force,
            product_id_a2_exclude: fulfillment.product_id_a2_exclude,
        };

        return (
            <PageLayout>
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.shippingMethodList.title" })}</Typography>
                <PageCard.Container>
                    <ScrollableTabsComponent ariaLabel="shipping-method-list-page-tabs" tabs={tabs} currentTab={tabs.length - 1} changeTab={changeTab} />
                    <Box mt="30px" />
                    <TitleContainer>
                        <TitleBar
                            title={<span className="titlebar-text">{I18n.formatMessage({ id: "pages.fulfillment.editTitle" }, { id: fulfillment.id })}</span>}
                            rightButtonsComponent={<FunctionalButton onClick={this.onDeleteButtonClick} type={FunctionalButtonIcon.delete} />}
                        />
                    </TitleContainer>
                    <Box mt="40px" />
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} enableReinitialize={true} validate={fulfillmentValidator}>
                        {props => (
                            <>
                                <FulfillmentForm formType={FormType.edit} formProps={props} fulfillment={fulfillment} shippingMethods={shippingMethods} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
                {!!fulfillment && (
                    <StatisticsPaper
                        createdAt={fulfillment.created_at}
                        createdBy={fulfillment.created_by?.name}
                        updatedAt={fulfillment.updated_at}
                        updatedBy={fulfillment.updated_by?.name}
                    />
                )}
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.fulfillment.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.fulfillment.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.onDeleteDialogClose}
                    onFunctionClick={this.onDeleteClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                />
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

const TitleContainer = styled.div`
    .titlebar-text {
        font-size: 24px;
    }
`;

export default withSnackbar(withRouter(FulfillmentEditPage));
