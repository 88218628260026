import React from "react";
import { Icon, IconButton, Paper, RootRef, Table, TableBody, TableCell, TableContainer, TableRow } from "@bigfish/admin-ui/core";
import { Path } from "Utils/Path";
import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle } from "react-beautiful-dnd";
import { Link } from "Components/Link";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Close } from "@bigfish/admin-ui/icons";
import { I18n } from "I18n/I18n";
import { BannerMinimal } from "Utils/ApiUtils";

type ComponentProps = {
    banners: BannerMinimal[];
    onChange: (newBannerMinimals: BannerMinimal[]) => void;
    onBannerEditClick?: (id: number) => void;
};

type Props = ComponentProps & WithSnackbarProps;

export const DraggableBannerTable = withSnackbar((props: Props) => {
    const onBannerRemove = (idToDelete: number) => {
        const newBannerMinimals = [...props.banners];
        const index = newBannerMinimals.findIndex(banner => banner.id === idToDelete);
        newBannerMinimals.splice(index, 1);
        props.onChange(newBannerMinimals);
    };

    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
        ...draggableStyle,
        ...(isDragging && {
            background: "rgb(235,235,235)",
        }),
    });

    const reorder = (list: BannerMinimal[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const newBannerMinimals = reorder(props.banners, result.source.index, result.destination.index);
        props.onChange(newBannerMinimals);
    };

    return (
        <TableContainer component={Paper}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {provided => (
                        <RootRef rootRef={provided.innerRef}>
                            <Table className="displayedBanners" aria-label="displayedBanners">
                                <TableBody>
                                    {props.banners.map((item, index) => (
                                        <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                            {(provided, snapshot) => (
                                                <TableRow
                                                    key={item.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                >
                                                    <TableCell className="draggable-table-icon-cell" align="left">
                                                        <Icon
                                                            className="fas fa-ellipsis-v draggable-icon draggable-ellipsis-icon"
                                                            title={I18n.formatMessage({ id: "common.move" })}
                                                        />
                                                        <Icon
                                                            className="fas fa-ellipsis-v draggable-icon draggable-ellipsis-icon"
                                                            title={I18n.formatMessage({ id: "common.move" })}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="draggable-table-icon-cell" align="left">
                                                        {props.onBannerEditClick ? (
                                                            <span className="draggable-icon-wrapper" onClick={() => props.onBannerEditClick!(item.id)}>
                                                                <Icon className="fa fa-pencil-alt draggable-icon" title={I18n.formatMessage({ id: "common.edit" })} />
                                                            </span>
                                                        ) : (
                                                            <Link to={Path.bannerEdit(`${item.id}`)}>
                                                                <span className="draggable-icon-wrapper">
                                                                    <Icon className="fa fa-pencil-alt draggable-icon" title={I18n.formatMessage({ id: "common.edit" })} />
                                                                </span>
                                                            </Link>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="left">{`ID:${item.id} - ${item.name}`}</TableCell>
                                                    <TableCell className="draggable-table-icon-cell" align="right" onClick={() => onBannerRemove(item.id)}>
                                                        <span className="draggable-icon-wrapper">
                                                            <IconButton className="table-delete-icon">
                                                                <Close />
                                                            </IconButton>
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </TableBody>
                            </Table>
                        </RootRef>
                    )}
                </Droppable>
            </DragDropContext>
        </TableContainer>
    );
});
