import React, { useState } from "react";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Button, TextField } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { NumberUtils } from "Utils/NumberUtils";
import { User } from "Api/graphql/admin/types";

type Props = {
    isVisible: boolean;
    onClose: () => void;
    user?: User;
    onReplaceToPlasticClick: (cardNumber: string) => void;
};

export const ReplaceCardToPlasticModal = (props: Props) => {
    const [cardNumber, setCardNumber] = useState<string>("");

    return (
        <DefaultModal
            title={I18n.formatMessage({ id: "pages.user.form.replaceCardToPlasticModal.title" })}
            rightButtonsComponent={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        props.onReplaceToPlasticClick(cardNumber);
                        props.onClose();
                    }}
                    disabled={!cardNumber}
                >
                    {I18n.formatMessage({ id: "pages.user.form.replaceCardToPlasticModal.submit" })}
                </Button>
            }
            leftButtonsComponent={
                <Button variant="outlined" color="primary" onClick={props.onClose}>
                    {I18n.formatMessage({ id: "common.cancel" })}
                </Button>
            }
            open={props.isVisible}
            onClose={props.onClose}
        >
            <TextField
                type="number"
                label={I18n.formatMessage({ id: "pages.user.form.replaceCardToPlasticModal.replacementCardNumberLabel" })}
                fullWidth
                variant="outlined"
                required
                value={cardNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                        setCardNumber(e.currentTarget.value);
                    }
                }}
            />
        </DefaultModal>
    );
};
