import { gql } from "@apollo/client";
import { adminFragment } from "Api/graphql/auth/fragments/adminFragment";

export const updateSettingMutation = gql`
    mutation updateSetting($id: String!, $input: SettingInput!) {
        updateSetting(id: $id, input: $input) {
            id
            type
            value
            options {
                title
                value
            }
            is_multi
            created_at
            updated_at
            created_by {
                ...Admin
            }
        }
    }
    ${adminFragment}
`;
