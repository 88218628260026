import { gql } from "@apollo/client";
import { productFragment } from "../fragments/productFragment";

export const updateProductMutation = gql`
    mutation updateProduct($id: Int!, $input: ProductUpdateInput!) {
        updateProduct(id: $id, input: $input) {
            ...Product
        }
    }
    ${productFragment}
`;
