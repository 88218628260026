import { gql } from "@apollo/client";
import { redirectionFragment } from "../fragments/redirectionFragment";

export const updateRedirectionMutation = gql`
    mutation updateRedirection($id: Int!, $input: RedirectionInput!) {
        updateRedirection(id: $id, input: $input) {
            ...Redirection
        }
    }
    ${redirectionFragment}
`;
