import { gql } from "@apollo/client";
import { personFragment } from "../fragments/personFragment";

export const updatePersonMutation = gql`
    mutation updatePerson($id: ID!, $input: PersonInput!) {
        updatePerson(id: $id, input: $input) {
            ...Person
        }
    }
    ${personFragment}
`;
