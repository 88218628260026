import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { TextContent } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { I18n } from "I18n/I18n";
import { ContentForm, ContentFormValues, ContentSaveType } from "./ContentForm";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { Document } from "./DocumentModal";
import Prompt from "Components/Prompt";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { NO_CATEGORY } from "./CategorySelect";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    isDeleteDialogVisible: boolean;
    content: TextContent | null;
};

class ContentEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        isDeleteDialogVisible: false,
        content: null,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const content = await Api.getContent(this.props.match.params.id!);
            this.setState({ content, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.contentList);
        }
    }

    private onSubmit = async (values: ContentFormValues, formikHelpers: FormikHelpers<ContentFormValues>): Promise<boolean> => {
        let saveType: ContentSaveType | undefined = values.saveType;
        let is_active = values.isActive;
        if (!saveType) {
            const isDelayed = values.activeFrom ? DateUtils.isAfter(DateUtils.parseISO(values.activeFrom), new Date()) : false;
            const wasDelayed = this.state.content?.active_from ? DateUtils.isAfter(DateUtils.parseISO(this.state.content?.active_from), new Date()) : false;
            if (values.isActive) {
                saveType = isDelayed && !wasDelayed ? ContentSaveType.schedule : ContentSaveType.update;
            } else {
                saveType = ContentSaveType.draft;
            }
        } else {
            switch (saveType) {
                case ContentSaveType.removeSchedule:
                case ContentSaveType.unPublish:
                    is_active = false;
                    break;
                case ContentSaveType.schedule:
                case ContentSaveType.publish:
                    is_active = true;
                    break;
                default:
            }
            formikHelpers.setFieldValue("saveType", undefined);
        }

        try {
            let active_from: string | null = null;
            let active_to: string | null = null;

            if (saveType !== ContentSaveType.removeSchedule) {
                if (values.activeFrom) {
                    active_from = DateUtils.format(DateUtils.parseISO(values.activeFrom), DateFormat.apiDateTime);
                }
                if (values.activeTo) {
                    active_to = DateUtils.format(DateUtils.parseISO(values.activeTo), DateFormat.apiDateTime);
                }
            }

            await Api.updateContent(this.state.content!.id, {
                title: values.title || null,
                lead: values.lead || null,
                lead_image: values.leadImage || null,
                meta_image: values.metaImage || null,
                content: values.content || null,
                meta_title: values.metaTitle || null,
                meta_keywords: values.metaKeywords || null,
                meta_description: values.metaDescription || null,
                author: values.author || null,
                is_active,
                active_from,
                active_to,
                category_id: values.categoryId === NO_CATEGORY.id ? null : values.categoryId,
            });

            const docsToDetach: Document[] = ((this.state.content!.documents as Document[]) || []).filter(d => values.documents.every(nd => d?.id !== nd.id));
            const docsToAttach: Document[] = values.documents.filter(d => (this.state.content!.documents || []).every(nd => d.id !== nd?.id));

            for (const document of docsToDetach) {
                try {
                    await Api.detachDocument(this.state.content!.id, document.id);
                } catch (error) {
                    this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.content.form.detachDocumentFailed" }, { document: document.file_name }), { variant: "error" });
                }
            }

            for (const document of docsToAttach) {
                try {
                    await Api.attachDocument(this.state.content!.id, document.id);
                } catch (error) {
                    this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.content.form.attachDocumentFailed" }, { document: document.file_name }), { variant: "error" });
                }
            }

            const content = await Api.getContent(this.state.content!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: `pages.content.save.succeed.${saveType}` }), { variant: "success" });
            this.setState({ content, isLoading: false });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: `pages.content.save.failed.${saveType}` }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private shouldBlock = (): boolean => {
        if (this.state.isDeleteDialogVisible) {
            this.setState({ isDeleteDialogVisible: false });
            return true;
        }
        return false;
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteContent(this.state.content!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.content.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ content: null });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.content.edit.deleteFailed" }), { variant: "error" });
        }
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.contentList} color="inherit">
                    {I18n.formatMessage({ id: "pages.contentList.breadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.content.edit.breadcrumb" })}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        if (this.state.isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }
        const { content } = this.state;
        if (!content) {
            return <Redirect to={Path.contentList} />;
        }

        const initialValues: ContentFormValues = {
            url: content.url,
            title: content.title,
            lead: content.lead || "",
            leadImage: content.lead_image,
            metaImage: content.meta_image,
            content: content.content || "",
            metaTitle: content.meta_title || "",
            metaKeywords: content.meta_keywords || "",
            metaDescription: content.meta_description || "",
            author: content.author || "",
            isActive: content.is_active,
            activeFrom: content.active_from ? DateUtils.format(DateUtils.parseISO(content.active_from), DateFormat.input) : "",
            activeTo: content.active_to ? DateUtils.format(DateUtils.parseISO(content.active_to), DateFormat.input) : "",
            categoryId: content.category?.id || NO_CATEGORY.id,
            documents: (content.documents as Document[] | null) || [],
        };
        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={content.title} rightButtonsComponent={<FunctionalButton onClick={this.onDeleteButtonClick} type={FunctionalButtonIcon.delete} />} />
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                        {props => (
                            <>
                                <ContentForm content={this.state.content!} formType={FormType.edit} formProps={props} />
                                <FunctionConfirmModal
                                    title={I18n.formatMessage({ id: "pages.content.edit.deleteModal.title" })}
                                    description={I18n.formatMessage({ id: "pages.content.edit.deleteModal.description" })}
                                    isVisible={this.state.isDeleteDialogVisible}
                                    onClose={this.onDeleteDialogClose}
                                    onFunctionClick={this.onDeleteClick}
                                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                                />
                                <Prompt
                                    when={props.dirty || this.state.isDeleteDialogVisible}
                                    hasSaveButton={props.isValid}
                                    shouldBlock={this.shouldBlock}
                                    onSave={() => this.onSubmit(props.values, props)}
                                />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(ContentEditPage));
