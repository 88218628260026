import { gql } from "@apollo/client";
import { productVariantFragment } from "../fragments/productVariantFragment";

export const updateProductVariantMutation = gql`
    mutation updateProductVariant($id: Int!, $input: ProductVariantInput!) {
        updateProductVariant(id: $id, input: $input) {
            ...ProductVariant
        }
    }
    ${productVariantFragment}
`;
