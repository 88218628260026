import { gql } from "@apollo/client";
import { todoFragment } from "../fragments/todoFragment";

export const updateTodoMutation = gql`
    mutation updateTodo($id: Int!, $input: TodoInput!) {
        updateTodo(id: $id, input: $input) {
            ...Todo
        }
    }
    ${todoFragment}
`;
