import { gql } from "@apollo/client";
import { calendarDayFragment } from "../fragments/calendarDayFragment";

export const setCalendarDayMutation = gql`
    mutation setCalendarDay($date: Date!, $input: CalendarDayInput!) {
        setCalendarDay(date: $date, input: $input) {
            ...CalendarDay
        }
    }
    ${calendarDayFragment}
`;
