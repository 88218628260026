import { gql } from "@apollo/client";
import { divisionFragment } from "../fragments/divisionFragment";

export const updateDivisionMutation = gql`
    mutation updateDivision($id: ID!, $input: DivisionInput!) {
        updateDivision(id: $id, input: $input) {
            ...Division
        }
    }
    ${divisionFragment}
`;
