import { gql } from "@apollo/client";
import { mailTemplateFragment } from "../fragments/mailTemplateFragment";

export const updateMailTemplateMutation = gql`
    mutation updateMailTemplate($id: Int!, $input: UpdateMailTemplateInput!) {
        updateMailTemplate(id: $id, input: $input) {
            ...MailTemplate
        }
    }
    ${mailTemplateFragment}
`;
