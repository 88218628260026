import { gql } from "@apollo/client";

export const createContentMutation = gql`
    mutation createContent($input: CreateContentInput!) {
        createContent(input: $input) {
            ... on TextContent {
                id
            }
        }
    }
`;
