import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { BenefitListItem, Department } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { DepartmentForm, DepartmentFormValues } from "./DepartmentForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton, FunctionalButtonIcon } from "Components/FunctionalButton";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    department: Department | null;
    isDeleteDialogVisible: boolean;
};

class DepartmentEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        department: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const department = await Api.getDepartment(this.props.match.params.id!);
            this.setState({ department, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.departmentList);
        }
    }

    private toggleDeleteDialogVisibility = () => {
        this.setState({ isDeleteDialogVisible: !this.state.isDeleteDialogVisible });
    };

    private deleteDepartment = async (): Promise<void> => {
        try {
            await Api.deleteDepartment(this.state.department!.id);
            this.props.history.push(Path.departmentList);
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
        }
    };

    private onSubmit = async (values: DepartmentFormValues, formikHelpers: FormikHelpers<DepartmentFormValues>): Promise<boolean> => {
        try {
            const department = await Api.updateDepartment(this.state.department!.id, {
                name: values.name,
                is_grouped: values.is_grouped,
                is_filter_enabled: values.is_grouped ? values.is_filter_enabled : undefined,
                is_map_enabled: values.is_grouped ? values.is_map_enabled : undefined,
                benefits: values.benefits.map((b: BenefitListItem, index: number) => {
                    return { id: b.id, order: index + 1 };
                }),
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.department.edit.succeed" }), { variant: "success" });
            this.setState({ department });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.department.edit.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.departmentList} color="inherit">
                    {I18n.formatMessage({ id: "pages.departmentList.breadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.department.edit.breadcrumb" })}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        if (this.state.isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }
        if (!this.state.department) {
            return <Redirect to={Path.departmentList} />;
        }

        const initialValues: DepartmentFormValues = {
            name: this.state.department.name,
            is_grouped: this.state.department.is_grouped,
            is_filter_enabled: this.state.department.is_grouped ? !!this.state.department.is_filter_enabled : false,
            is_map_enabled: this.state.department.is_grouped ? !!this.state.department.is_map_enabled : false,
            divisions: [...this.state.department.divisions],
            benefits: [...this.state.department.benefits],
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar
                    title={this.state.department.name}
                    rightButtonsComponent={<FunctionalButton onClick={this.toggleDeleteDialogVisibility} type={FunctionalButtonIcon.delete} />}
                />
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                        {props => (
                            <>
                                <DepartmentForm formType={FormType.edit} formProps={props} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>

                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.department.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.department.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.toggleDeleteDialogVisibility}
                    onFunctionClick={this.deleteDepartment}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(DepartmentEditPage));
