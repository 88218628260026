import { gql } from "@apollo/client";
import { galleryFragment } from "../fragments/galleryFragment";

export const updateGalleryMutation = gql`
    mutation updateGallery($id: ID!, $input: UpdateGalleryInput!) {
        updateGallery(id: $id, input: $input) {
            ...Gallery
        }
    }
    ${galleryFragment}
`;
