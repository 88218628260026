import { gql } from "@apollo/client";
import { roleFragment } from "../fragments/roleFragment";

export const updateRoleMutation = gql`
    mutation updateRole($id: Int!, $input: RoleInput!) {
        updateRole(id: $id, input: $input) {
            ...Role
        }
    }
    ${roleFragment}
`;
