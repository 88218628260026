import { gql } from "@apollo/client";
import { productFragment } from "../fragments/productFragment";

export const updateProductBundleMutation = gql`
    mutation updateProductBundle($id: Int!, $input: ProductBundleInput!) {
        updateProductBundle(id: $id, input: $input) {
            ...Product
        }
    }
    ${productFragment}
`;
